import API from './API';

const ConfigSalida = {

    getData: () => new Promise(
        (resolve, reject ) => {
         
            API.get('/api/export/GetCS')
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),

    addData: (params) => new Promise(
        (resolve, reject ) => {
         
            API.post('/api/export/InsertCS',params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),
    updateData: (params) => new Promise(
        (resolve, reject ) => {
         
            API.post('/api/export/UpdateCS',params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),
    deleteData: (params) => new Promise(
        (resolve, reject ) => {
         
            API.delete('/api/export/DeleteCS?id=' + params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    )

}

export default ConfigSalida;