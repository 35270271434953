import React, { useEffect, } from 'react'
import styles from './index.module.css'
import TextField from '@material-ui/core/TextField';
import Modal from 'react-bootstrap/Modal'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CloseIcon from '@mui/icons-material/Close';
import InputElementService from '../../services/InputElementService.service';
import Button from "@material-ui/core/Button";
import FormHelperText from '@material-ui/core/FormHelperText';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    borderWidth:'1px',
    borderStyle:'solid',
    borderRadius: "5px",
    '& .MuiSelect-select':{
      height: '280px',
      width: '259px',
      '& option':{
        height: '39px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
        fontFamily: 'Quicksand',
        fontSize: '12px',
      },
      '& option:checked, option:focus, option:active, option:hover':{
        background: 'linear-gradient(#C32032,#C32032)',
        backgroundColor: '#C32032 !important',
        color: '#FFFFFF !important',
      }
    }    
  },
  buscar:{
    "& .MuiOutlinedInput-root": {
      width: '216px',
      height: '31px',
      marginTop: '10px',
      "& fieldset": {
        borderRadius: "20px",
        width: '216px',
        height: '31px',
      },
    },
  },
}));
const ModalCampo = (props) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    tabla: [],
    campo: [],
    camposFilter: "",
    tablaSelect:"",
    campos: [],
    campoSelected: {}
  });

  const [tablas, setTablas] = React.useState([]);
  const [tablasFilter, setTablasFilter] = React.useState([]);

  const [valid, setValid] = React.useState({
    tabla: false,
    campo: false,
});
  
  useEffect(() => {
    const requests = [ InputElementService.getEntidad(), InputElementService.getEntidadCampoReferencia()];
    Promise.all(requests)
      .then(([entidades, camp]) => {
        const entidadesTratadas = entidades.map(e => {
            let campos = camp.filter(c => c.identidad === e.id) || [];
            return {...e, campos}
        }).filter(e => e.campos.length > 0)
        setTablas(entidadesTratadas);
        setTablasFilter(entidadesTratadas);     
      })
      .catch(console.log)
    
	}, []);

 const handleValid = () =>{
  if(!state.tabla.length){
    setValid({...valid, tabla: true});
  }else if(!state.campo.length){
    setValid({...valid, campo: true});
  }else{    
    let tablaCampo = state.tabla[0].toLowerCase().replace(/ /g, "")+"."+state.campo[0].toLowerCase();
    props.handleUpdateCampo(tablaCampo);
    //Si campo no es tipo fecha se deshabilita select de funcion
    state.campoSelected.tipodato === "DateTime" ? props.handleUpdateCampo(tablaCampo, false) : props.handleUpdateCampo(tablaCampo, true);
    props.handleModalCampo();
  }
 }

  const handleSearchCampo = (event) => {
    setState({ ...state, camposFilter: state.campos.filter(campo => campo.campo.includes(event.target.value)) })
  }

  const handleSearchTabla = (event) => {
    setTablasFilter(tablas.filter(tabla => tabla.nombre.includes(event.target.value)));
  }

  const handleChange = (event) => {
    if(event.target.name === "campo"){
      let campoSelected = state.camposFilter.filter(campo => campo.campo===event.target.value);
      setState({
        ...state, 
        campoSelected: campoSelected[0], 
        [event.target.name]: [event.target.value]
      });
      setValid({...valid, campo: false});
    }else{
      let tableSelected = tablas.filter(tabla => tabla.nombre===event.target.value);
      setState({
        ...state, [event.target.name]: [event.target.value],
        tablaSelect:tableSelected,
        camposFilter:tableSelected[0].campos,
        campos: tableSelected[0].campos,
        campoSelected : {}
      });
      setValid({tabla:false, campo: false});
    }
  };

  return (
    <>
      <Modal size="lg" show={props.modalCampo} onHide={props.handleModalCampo} >
        <Modal.Body>
          <div className={styles.headerModal}>
              <div className={styles.headerLeftModal}>
                  <span className='title-view'>Selección de Campos</span>
              </div>
              <div className={styles.headerRigthModal}>
                  <Button className='add-button' onClick={props.handleModalCampo}>
                      <CloseIcon />
                  </Button>                            
              </div>
          </div>
          <form >
            <div className={styles.radioGroup}>
              <div className={classes.buscar}>
                <InputLabel shrink htmlFor="tablaFilter">
                  Tabla
                </InputLabel>
                <TextField id="tablaFilter" name="tablaFilter" onChange={handleSearchTabla} placeholder='Buscar'  variant="outlined" />
              </div>
              <div className={classes.buscar}>
                <InputLabel shrink htmlFor="tablaFilter">
                  Campos
                </InputLabel>
                <TextField id="campoFilter" name="campoFilter" onChange={handleSearchCampo} placeholder='Buscar' variant="outlined" />
              </div>
            </div>
            <div className={styles.radioGroup}>
              <FormControl className={classes.formControl}>
                <Select
                  className={classes.root}   
                  multiple       
                  native
                  name="tabla"
                  value={state.tabla}
                  onChange={handleChange}
                  inputProps={{
                    id: 'select-tabla',
                  }}
                >
                  {tablasFilter.length && tablasFilter.map((tabla,index) => (
                    <option key={index} value={tabla.nombre}>
                      {tabla.nombre}
                    </option>
                  ))}
                </Select>
                {valid.tabla ? <FormHelperText>Por favor agregue un valor para tabla.</FormHelperText> : ""}
              </FormControl>
                
              <FormControl className={classes.formControl}>
                <Select
                  className={classes.root}
                  multiple
                  native
                  name="campo"
                  value={state.campo}
                  onChange={handleChange}
                  inputProps={{
                    id: 'select-campo',
                  }}
                >
                  {state.camposFilter && state.camposFilter.map((campo) => (
                    <option key={campo.campo} value={campo.campo}>
                      {campo.campo}
                    </option>
                  ))}
                </Select>
                {valid.campo ? <FormHelperText>Por favor agregue un valor para campo.</FormHelperText> : ""}
              </FormControl>
            </div>
            <div className={styles.radioGroup}>
                <Button className={styles.saveButton} onClick={() => { handleValid() }}>
                    Aceptar
                </Button>
                <Button className={styles.cancelButton} onClick={() => { props.handleModalCampo() }}>
                    Cancelar
                </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalCampo