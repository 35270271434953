import axios from "axios";
import https from 'https';

let BASE_URL = process.env.REACT_APP_API_URL;

const API = axios.create({
  baseURL: BASE_URL,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});

// Add a request interceptor
API.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.token = window.localStorage.token || "";

    if (window.localStorage.userInfo) {
      const user = JSON.parse(window.localStorage.userInfo);
      config.headers.UserId = user.id || 0;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default API;
