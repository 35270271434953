import React from "react";
import { DataGrid, GridToolbarExport, GridToolbarContainer , esES } from "@mui/x-data-grid";

function MyExportButton() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const TableData = (props) => {
  //ejemplo
  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "firstName",
      headerName: "First name",
      width: 150,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 150,
      editable: true,
    },
    {
      field: "age",
      headerName: "Age",
      type: "number", //si es tipo numero, en la tabla se ve las opciones del lado izq
      width: 110,
      editable: true,
    },
  ];
  
  return (
    <div className={props.containerClass || "container-table"}>
      <DataGrid
        className={props.className}
        rowsPerPageOptions={[10]}
        localeText={esES.props.MuiDataGrid.localeText}
        rows={props.rows}
        getRowClassName={props.getRowClassName}
        columns={props.columns ? props.columns : columns}
        pageSize={10}
        checkboxSelection={!props.skipCheckbox} //columna de checkbox
        disableSelectionOnClick // que no se seleccione el checkbox al editar
        onSelectionModelChange={(itm) => props.setSelectRows(itm)} //rows seleccionadas
        onRowClick={(itm) => props.onRowClick(itm)} //click en un row /modal de editar
        components={!props.skipExport && {
          Toolbar: MyExportButton,
        }}
      />
    </div>
  );
};

export default TableData;
