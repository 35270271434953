import './Styles.css';
import {Link} from 'react-router-dom'
const Paginator = ( ) => {
    const pathname = window.location.pathname;
    const query = pathname == '/home' ? 'Inicio' : 'Inicio' + pathname;
    const queryAll = query.split('/');
    return (
        <div className = 'container-paginator' >
            { queryAll.map(( item,  index ) => (
                <>
                  <Link className = 'link-header'  >    { ` ${item}   / `} </Link>   &nbsp;
                </>
            ) ) }
        </div>
    )
}

export default Paginator