import API from './API';

const ServiceCalculo = {

    getData: () => new Promise(
        (resolve, reject ) => {
         
            API.get('/api/export/GETTC')
            .then( res => res.data)
            .then(data => {
                resolve(data)
                console.log(data)
            })
            .catch( error => reject(error))
        }
    ),

    addData: (params) => new Promise(
        (resolve, reject ) => {
         
            API.post('/api/export/insertTC',params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),
    updateData: (params) => new Promise(
        (resolve, reject ) => {
         
            API.post('/api/export/UpdateTC',params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),
    deleteData: (params) => new Promise(
        (resolve, reject ) => {
         
            API.delete('/api/export/DeleteTC?id='+params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),
     /// DetalleCalculo
     getDataDetalle: () => new Promise(
        (resolve, reject ) => {
         
            API.get('/api/export/GETTCD')
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),

    addDataDetalle: (params) => new Promise(
        (resolve, reject ) => {
         
            API.post('/api/export/insertTCD',params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),
    updateDataDetalle: (params) => new Promise(
        (resolve, reject ) => {
         
            API.post('/api/export/updateTCD',params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),

    ejecutarCalculo: (params) => new Promise(
        (resolve, reject ) => {
         
            API.post('/api/export/GetConveniosActivos',params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    )

}

export default ServiceCalculo;