import API from './API';

const ServiceLogica = {

    getData: () => new Promise(
        (resolve, reject ) => {
         
            API.get('/api/export/GetTL')
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),

    addData: (params) => new Promise(
        (resolve, reject ) => {
         
            API.post('/api/export/insertTL',params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),
    updateData: (params) => new Promise(
        (resolve, reject ) => {
         
            API.post('/api/export/updateTL',params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),
    deleteData: (params) => new Promise(
        (resolve, reject ) => {
         
            API.delete('/api/export/deleteTL?id='+params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),
        /// Detallelogico
        getDataDetalle: () => new Promise(
            (resolve, reject ) => {
             
                API.get('/api/export/GetTLD')
                .then( res => res.data)
                .then(data => {
                    resolve(data)
                })
                .catch( error => reject(error))
            }
        ),
    
        addDataDetalle: (params) => new Promise(
            (resolve, reject ) => {
             
                API.post('/api/export/insertTLD',params)
                .then( res => res.data)
                .then(data => {
                    resolve(data)
                })
                .catch( error => reject(error))
            }
        ),
        updateDataDetalle: (params) => new Promise(
            (resolve, reject ) => {
             
                API.post('/api/export/updateTLD',params)
                .then( res => res.data)
                .then(data => {
                    resolve(data)
                })
                .catch( error => reject(error))
            }
        )

}

export default ServiceLogica;