import React from "react";
import TextField from "@material-ui/core/TextField";

const FieldText = (props) => {
	return (
		<div className='inputText' >
            <label  htmlFor={props.name} style={props.labelStyle}>
				{props.labelText}
			</label>
			<TextField
                {...props}
				//label={props.labelText}
				variant='outlined'
				disabled={props.disabled}
			/>
		</div>
	);
};

export default FieldText;
