import { makeStyles } from "@material-ui/core/styles";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import * as React from "react";

const useStyles = makeStyles({
  label: {
    flex: "auto !important",
    margin: " 0px 0px 5px 0px !important",
    color: "#484848",
    font: "normal normal bold 12px Quicksand-Bold",
  },
});

const CssSelect = styled(Select)({
  minWidth: "200px",
  maxHeight: "31px",
  borderRadius: "5px !important",
  font: "normal 12px Futura-Medium",
  letterSpacing: "0px",
  opacity: "1",
  fontWeight: "600",
  color: "#8B8B8B",
  "& fieldset": {
    borderColor: "#707070",
    top: "0px !important",
    borderRadius: "5px",
    maxHeight: "32px",
    "& legend": {
      display: "none !important",
    },
  },
  "& select": {
    font: "normal normal normal 12px Futura-Medium !important",
  },
});

export default function SelectToolbar(props) {
  const classes = useStyles(props);
  return (
    <div
      className="inputText"
      style={{
        marginBottom: "10px",
        marginTop: "10px",
        display: "grid"
      }}
    >

      <label htmlFor={props.name} className={classes.label}>
        {props.labelText}
      </label>
      <CssSelect
        className="select"
        variant="outlined"
        native
        {...props}
      >
        {(props.isOptional !== undefined ? props.isOptional : true) && (
          <option aria-label="None" value="" />
        )}
        {props.data.map((ele) => {
          return <option value={ele[props.optV]}> {ele[props.optL]}</option>;
        })}
      </CssSelect>
    </div>
  );
}
