import React from "react";
import Select from "@material-ui/core/Select";

const SelectField = (props) => {
	const {optV, optL, data, labelText, ...rest} = props;
	return (
		<div className='form-select'>
			<label>{labelText}</label>
			<Select className='select' variant='outlined' native {...rest} >
				<option aria-label='None' value='' />
				{data.map((ele) => {
					return <option key={ele[optV]} value={ele[optV]}> {ele[optL]}</option>;
				})}
			</Select>
		</div>
	);
};

export default SelectField;





