import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import React, { useContext } from 'react';
import { MotorContext } from "../../../../context/MotorContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0, 0, 0, 0.3)',
    transition: 'opacity 0.2s',
    '& .MuiCircularProgress-root':{
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
    '& .MuiTypography-body1':{
        position: 'absolute',
        top: '50%',
        left: '50%',
    }
  },
  hide: {
      display: 'none',
  }
}));

export default function Loader(props) {
  const classes = useStyles();

  const {
    loading
  } = useContext(MotorContext);

  return (
    <div className={loading ? classes.root : classes.hide}>
      {/*<CircularProgress color="#434343" disableShrink />*/}
      <Typography>{'Cargando ...'}</Typography>
    </div>
  );
}
