import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import AddBoxIcon from "@material-ui/icons/AddBox";
//iconos
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AlertError from "componets/ErrorMessage";
import FieldText from "componets/Input/FieldText";
import FieldTextArea from "componets/Input/FieldTextArea";
import RadioField from "componets/Input/RadioField";
import SelectField from "componets/Input/SelectField";
import ComponentModal from "componets/Modal";
import SuccessMessage from "componets/SuccessMessage";
import { MotorContext } from "context/MotorContext";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import ModalDelete from "../../componets/Custom/Utils/ModalDelete";
import { estado } from "../../componets/DataSelect/DataArray";
import Modal from "../../componets/NewModal/Modal.jsx";
//api
import ServiceSalida from "../../Services/ConfigSalida.service";
import ServiceCalculo from "../../Services/ServiceCalculo.service";
import ServiceLogica from "../../Services/ServiceLogica.service";
//componentes
import TableData from "componets/TableData/index";
//Datos usuario
const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
const dateYMD = moment().format();

const ConfigSalida = (props) => {
  const [rows, setRows] = useState([]);
  const [selectRows, setSelectRows] = useState([]); //rows seleccioandas
  const [actualizar, setActualizar] = useState(false);

  //Modal creacion
  const [stateModal, setStateModal] = useState(false);

  //Modal modificacion
  const [stateModalEdit, setStateModalEdit] = useState(false);
  const [rowEditData, setRowEditData] = useState([]); //celda modificada
  const [modalDetalles, setModalDetalles] = useState(false); //modal detalles

  //Opciones de id calculo y id logica
  const [optionsCalculo, setOptionsCalculo] = useState([]);
  const [optionsLogica, setOptionsLogica] = useState([]);

  //editar
  const [disabled, setDisabled] = useState(true);

  //notificacion
  const [noti, setNoti] = useState(false);
  const [messageNoti, setMessageNoti] = useState("");

  //-------------------Confirmacion-------------------
  const [severity, setSeverity] = useState("success");
  const [openModalDelete, setOpenModalDelete] = React.useState(false);

  const {
    modalConfirmCancelProps, setModalConfirmCancelProps
  } = useContext(MotorContext);

  const handleConfirmClose = () => {
    setModalConfirmCancelProps({
      ...modalConfirmCancelProps,
        open: true,
        onYes: setStateModal,
    });
  }

  const handleConfirmCloseEdit = () => {
    setModalConfirmCancelProps({
      ...modalConfirmCancelProps,
        open: true,
        onYes: setStateModalEdit,
    });
  }

  const handleOpenModalDelete = () => {
    setOpenModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleDeleteItem = () => {
    if (selectRows.length) {
      handleOpenModalDelete();
    } else {
      showNoti("seleccione un elemento de la tabla", "error");
    }
  };

  const showNoti = (mensaje, sev) => {
    setMessageNoti(mensaje);
    setSeverity(sev);
    setNoti(true);
  };
  ///-------------------------------------------------

  const handleModal = () => {
    document.getElementById("salida").reset();
    setStateModal(!stateModal);
  };

  const handleModalEdit = () => {
    setStateModalEdit(!stateModalEdit);
  };

  const handleModalDetalles = () => {
    setModalDetalles(!modalDetalles);
  };

  //Validacion del formulario
  const validation = Yup.object().shape({
    nombre: Yup.string().required("Por favor agregue un valor para nombre."),
    descripcion: Yup.string().required(
      "Por favor agregue un valor para descripcion."
    ),
    idtablerocalculo: Yup.string().required(
      "Por favor agregue un valor para tablero calculo."
    ),
    idtablerologico: Yup.string().required(
      "Por favor agregue un valor para tablero lógico."
    ),
  });

  //Columnas de la tabla
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 320,
      editable: false,
    },
    {
      field: "idtablerologico",
      headerName: "Configuración Lógica",
      width: 320,
      valueGetter: (params) => fiendEE(optionsLogica, params.value),
    },
    {
      field: "idtablerocalculo",
      headerName: "Configuración Cálculo",
      width: 400,
      valueGetter: (params) => fiendEE(optionsCalculo, params.value),
    },
    {
      field: "",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <strong>
          <div className="column-actions">
            <Button
              className="add-button"
              onClick={() => {
                setDisabled(false);
                setRowEditData(params.row);
                handleModalEdit();
              }}
            >
              <EditIcon className="black" />
            </Button>
          </div>
        </strong>
      ),
      filterable: false
    },
  ];

  //Actualizacion de datos de la tabla
  useEffect(() => {
    ServiceSalida.getData()
      .then((response) => {
        setRows(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  }, [actualizar]);

  //Opciones de calculo y logica
  useEffect(() => {
    ServiceLogica.getData()
      .then((response) => {
        setOptionsLogica(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));

    ServiceCalculo.getData()
      .then((response) => {
        setOptionsCalculo(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  }, []);

  //Agregar datos
  const addData = (values, actions) => {
    values.usuariocreacion = userInfo.id;
    ServiceSalida.addData(values)
      .then((response) => {
        setActualizar(!actualizar);
        handleModal();
        showNoti("Elemento creado", "success");
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  //Buscar elemento en la lista de datos
  const findElement = (listData, id) => {
    return listData.find((ele) => ele.id === id);
  };

  const fiendEE = (listData, value) => {
    const data = listData.find((ele) => ele.id === value);
    return data ? data.nombre : " ";
  };

  //Eliminar datos
  const deleteData = () => {
    selectRows.map((ele) => {
      const rowelement = findElement(rows, ele);

      return ServiceSalida.deleteData(rowelement.id)
        .then((response) => {
          console.log(response);
          setActualizar(!actualizar);
          setSelectRows([]);
          response.error.forEach((e) => showNoti(e, "error"));
          response.success.forEach((e) => showNoti(e, "success"));
        })
        .catch((error) => console.log(`Ocurrio un error: ${error}`));
    });
  };

  const editData = (values, actions) => {
    values.fechaultimamodificacion = dateYMD.slice(0, 10);
    values.usuarioultimamodificacion = userInfo.id;

    values.id = rowEditData.id;
    ServiceSalida.updateData(values)
      .then((response) => {
        setActualizar(!actualizar);
        handleModalEdit();
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  return (
    <div className="config-salida">
      <div className="container-title">
        <p className="title"> Campo salida </p>

        <SuccessMessage
          open={noti}
          setNoti={setNoti}
          message={messageNoti}
          setMessageNoti={setMessageNoti}
          severity={severity}
        />
        <div className="actions">
          <Button className="add-button" onClick={handleModal}>
            <AddBoxIcon />
          </Button>
          {!selectRows.length ? (
            <Button className="add-button" onClick={handleDeleteItem} disabled>
              <DeleteIcon color="disabled" />
            </Button>
          ) : (
            <Button className="add-button" onClick={handleDeleteItem}>
              <DeleteIcon />
            </Button>
          )}
        </div>
      </div>

      <TableData
        columns={columns}
        rows={rows}
        onRowClick={() => {/* do nothing */}}
        setSelectRows={setSelectRows}

        
      />
      {/* Modal crear elemento */}
      <Modal
        title={"Agregar registro"}
        open={stateModal}
        onClose={handleModal}
        enableReinitialize
        setStateModal={handleConfirmClose}
        className="modal600"
      >
        <Formik
          initialValues={{
            nombre: "",
            idtablerocalculo: "32",
            idtablerologico: "",
            descripcion: "",
            tipoestado: 1,
          }}
          resetForm
          validationSchema={validation}
          onSubmit={addData}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form id="salida">
              <div className="Containerform">
                <div className="forms">
                  <FieldText
                    name="nombre"
                    type="text"
                    labelText="Nombre"
                    value={values.nombre}
                    onChange={handleChange}
                  />
                  {touched.nombre && errors.nombre && (
                    <AlertError message={errors.nombre} />
                  )}
                  <SelectField
                    name="idtablerologico"
                    data={optionsLogica}
                    optV={"id"}
                    labelText={"Tablero lógico"}
                    optL={"nombre"}
                    value={values.idtablerologico}
                    onChange={handleChange}
                  />
                  {touched.idtablerologico && errors.idtablerologico && (
                    <AlertError message={errors.idtablerologico} />
                  )}
                  <SelectField
                    name="idtablerocalculo"
                    data={optionsCalculo}
                    optV={"id"}
                    labelText={"Tablero cálculo"}
                    optL={"nombre"}
                    value={values.idtablerocalculo}
                    onChange={handleChange}
		          			isOptional={false}
                  />
                  {touched.idtablerocalculo && errors.idtablerocalculo && (
                    <AlertError message={errors.idtablerocalculo} />
                  )}
                  <FieldTextArea
                    name="descripcion"
                    labelText="Descripción"
                    value={values.descripcion}
                    onChange={handleChange}
                    rows={2}
                    disabled={false}
                    multiline
                  />
                  {touched.descripcion && errors.descripcion && (
                    <AlertError message={errors.descripcion} />
                  )}
                </div>
                <DialogActions>
                  <Button className="save-button" type="submit">
                    ACEPTAR
                  </Button>
                </DialogActions>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      {/* Modal editar elemento */}
      <Modal
        title={"Editar registro"}
        open={stateModalEdit}
        onClose={handleModalEdit}
        setStateModal={handleConfirmCloseEdit}
        className="modal600"
      >
        <Formik
          initialValues={{
            nombre: rowEditData.nombre,
            idtablerocalculo: rowEditData.idtablerocalculo,
            idtablerologico: rowEditData.idtablerologico,
            descripcion: rowEditData.descripcion,
            tipoestado: rowEditData.tipestado,
          }}
          enableReinitialize
          validationSchema={validation}
          onSubmit={editData}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form>
              <div className="Containerform">
                <div className="forms">
                  <FieldText
                    name="nombre"
                    type="text"
                    labelText="Nombre"
                    value={values.nombre}
                    onChange={handleChange}
                    disabled={disabled}
                  />{" "}
                  {touched.nombre && errors.nombre && (
                    <AlertError message={errors.nombre} />
                  )}
                  <SelectField
                    name="idtablerologico"
                    data={optionsLogica}
                    optV={"id"}
                    labelText={"Tablero lógico"}
                    optL={"nombre"}
                    value={values.idtablerologico}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  {touched.idtablerologico && errors.idtablerologico && (
                    <AlertError message={errors.idtablerologico} />
                  )}
                  <SelectField
                    name="idtablerocalculo"
                    data={optionsCalculo}
                    optV={"id"}
                    labelText={"Tablero cálculo"}
                    optL={"nombre"}
                    value={values.idtablerocalculo}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  {touched.idtablerocalculo && errors.idtablerocalculo && (
                    <AlertError message={errors.idtablerocalculo} />
                  )}
                  <FieldTextArea
                    name="descripcion"
                    labelText="Descripción"
                    value={values.descripcion}
                    onChange={handleChange}
                    disabled={disabled}
                    rows={2}
                    multiline
                  />
                  {touched.descripcion && errors.descripcion && (
                    <AlertError message={errors.descripcion} />
                  )}
                </div>
                <DialogActions>
                  <Button className="save-button" type="submit">
                    ACEPTAR
                  </Button>
                </DialogActions>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      {/* Modal de detalles */}
      <ComponentModal
        title={"Detalles del registro"}
        open={modalDetalles}
        onClose={handleModalDetalles}
        setStateModal={setModalDetalles}
      >
        <Formik
          initialValues={{
            nombre: rowEditData.nombre,
            idtablerocalculo: rowEditData.idtablerocalculo,
            idtablerologico: rowEditData.idtablerologico,
            descripcion: rowEditData.descripcion,
            tipoestado: parseInt(rowEditData.tipestado),
            usuariocreacion: rowEditData.usuariocreacion,
            fechacreacion: rowEditData.fechacreacion,
            usuarioultimamodificacion: rowEditData.usuarioultimamodificacion,
            fechaultimamodificacion: rowEditData.fechaultimamodificacion,
          }}
          enableReinitialize
          validationSchema={validation}
          onSubmit={editData}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form>
              <FieldText
                name="nombre"
                type="text"
                labelText="Nombre"
                value={values.nombre}
                onChange={handleChange}
                disabled={disabled}
              />{" "}
              <SelectField
                name="idtablerologico"
                data={optionsLogica}
                optV={"id"}
                labelText={"Tablero lógico"}
                optL={"idtablerologico"}
                value={values.idtablerologico}
                onChange={handleChange}
                disabled={disabled}
              />
              <SelectField
                name="idtablerocalculo"
                data={optionsCalculo}
                optV={"id"}
                labelText={"Tablero cálculo"}
                optL={"nombre"}
                value={values.idtablerocalculo}
                onChange={handleChange}
                disabled={disabled}
              />
              <FieldText
                name="descripcion"
                type="text"
                labelText="Descripción"
                value={values.descripcion}
                onChange={handleChange}
                disabled={disabled}
              />
              <RadioField
                label="Tipo de estado"
                name="tipoestado"
                data={estado}
                onChange={handleChange}
                checked={values.tipoestado}
                value={values.tipoestado}
                disabled={disabled}
              />
              <FieldText
                name="usuariocreacion"
                type="text"
                labelText="Usuario creación"
                value={values.usuariocreacion}
                onChange={handleChange}
                disabled={disabled}
              />
              <FieldText
                name="fechacreacion"
                type="text"
                labelText="Fecha de creación"
                value={values.fechacreacion}
                onChange={handleChange}
                disabled={disabled}
              />
              <FieldText
                name="usuarioultimamodificacion"
                type="text"
                labelText="Usuario última modificacion"
                value={values.usuarioultimamodificacion}
                onChange={handleChange}
                disabled={disabled}
              />
              <FieldText
                name="fechamodificacion"
                type="text"
                labelText="Fecha última modificacion"
                value={values.fechaultimamodificacion}
                onChange={handleChange}
                disabled={disabled}
              />
              <DialogActions>
                <Button className="save-button" type="submit">
                  Guardar
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
        <ModalDelete
          open={openModalDelete}
          onClose={handleCloseModalDelete}
          onYes={deleteData}
        />
      </ComponentModal>
    </div>
  );
};

export default ConfigSalida;
