import API from "../../../Services/API";

const InputElementService = {
  getEntidad: () =>
    new Promise((resolve, reject) => {
      API.get("/api/export/GetE")
        .then((res) => res.data)
        .then((data) => {
          resolve(data);

          // console.log("fromservice...get Entidad: ",data)
        })
        .catch((error) => reject(error));
    }),
  getEntidadCampoReferencia: (tienda) =>
    new Promise((resolve, reject) => {
      if (tienda == undefined) {
        API.get("/api/export/GetEC")
          .then((res) => res.data)
          .then((data) => {
            resolve(data);
            console.log("fromservice...Entidad Campo Referencia: ", data);
            return data;
          })
          .catch((error) => reject(error));
      } else {
        API.get("/api/export/GetCamposReferencia?nombretabla=" + tienda)
          .then((res) => res.data)
          .then((data) => {
            console.log("GetCamposReferencia");
            resolve(data);
            return data;
          })
          .catch((error) => reject(error));
      }
    }),
  getEntidadCampoFiltrado: (tienda) =>
    new Promise((resolve, reject) => {
      if (tienda == undefined) {
        API.get("/api/export/GetEC")
          .then((res) => res.data)
          .then((data) => {
            resolve(data);
            console.log("fromservice...Entidad Campo Filtrado: ", data);
            return data;
          })
          .catch((error) => reject(error));
      } else {
        API.get("/api/export/GetCamposfiltrado?nombretabla=" + tienda)
          .then((res) => res.data)
          .then((data) => {
            resolve(data);
            return data;
          })
          .catch((error) => reject(error));
      }
    }),
  getElementosEntrada: () =>
    new Promise((resolve, reject) => {
      API.get("/api/export/GetCE")
        .then((res) => res.data)
        .then((data) => {
          resolve(data);
          // console.log("fromservice...Elementos de entrada(Datos Tabla): ",data)
        })
        .catch((error) => reject(error));
    }),
  postUpdateElementosEntrada: (params) =>
    new Promise((resolve, reject) => {
      API.post("/api/export/updatece", params)
        .then((res) => res.data)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    }),
  postNewElementosEntrada: (params) =>
    new Promise((resolve, reject) => {
      API.post("/api/export/insertce", params)
        .then((res) => res.data)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    }),
  getCamposReferencia: (idElementoEntrada) =>
    new Promise((resolve, reject) => {
      API.get(
        "/api/Export/GetCamposreferenciaFiltrados?id=" + idElementoEntrada
      )
        .then((res) => res.data)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    }),
  deleteCampoEntrada: (idElementoEntrada) =>
    new Promise((resolve, reject) => {
      API.delete("/api/Export/DeleteCE?id=" + idElementoEntrada)
        .then((res) => res.data)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    }),
    getEntidadValorMultiple: (params) =>
    new Promise((resolve, reject) => {
      API.get(`/api/Export/GetEMFiltrado?nombretabla=${params.nombretabla}&nombrecampo=${params.nombrecampo}`)
        .then((res) => res.data)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    }),
};

export default InputElementService;
