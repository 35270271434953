import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "componets/Custom/Input/Select/SelectToolbar";
import FieldText from "componets/Custom/Input/TextField/TextFiellToolBar";
import SuccessMessage from "componets/SuccessMessage";
import TableData from "componets/TableData";
import React, { useEffect, useState } from "react";
import ServiceEjecucion from "../../Services/ServiceEjecucion.service";
import ComponentModal from "./ComponentModal";

const updateSelectContract = (state, setSelectContract) => {
  if (state) {
    setSelectContract([]);
  }
};

const handleOnmessage = (tableLogs, modalEvents, setModalEvents, actualizar, setActualizar, res, moneyFormater) => {
  if (tableLogs) {
    setModalEvents(!modalEvents);
    setActualizar(!actualizar);
    let rowsNew = "";
    for (let r of res) {
      rowsNew += `
                    <tr>
                        <td>${r.claveconvenio || ""}</td>
                        <td>${r.tipo || ""}</td>
                        <td>${r.fechadepago || ""}</td>
                        <td>${(
          r.estatusProyeccion || ""
        ).toUpperCase()}</td>
                        <td>${moneyFormater(r.monto) || ""}</td>
                    </tr>
                `;
    }
    tableLogs.innerHTML = rowsNew;
  }
};

const handleEjecutaMotor = (selectYear, selectMonth, selectContract, showNoti, handleModalEventos) => {
  if (selectYear == "" || selectMonth == "") {
    showNoti("error", "Datos incompletos");
  } else {
    if (selectMonth <= 9) {
      if (selectContract != "") {
        let period = [
          {
            periodo: `${selectYear}-0${selectMonth}`,
            contrato: `${selectContract}`,
          },
        ];
        console.log("Periodo enviado\n", period);
        handleModalEventos(period);
      } else {
        let period = [
          {
            periodo: `${selectYear}-0${selectMonth}`,
          },
        ];
        console.log("Periodo enviado\n", period);
        handleModalEventos(period);
      }
    } else if (selectMonth >= 10) {
      if (selectContract != "") {
        let period = [
          {
            periodo: `${selectYear}-${selectMonth}`,
            contrato: `${selectContract}`,
          },
        ];
        console.log("Periodo enviado\n", period);
        handleModalEventos(period);

      } else {
        let period = [
          {
            periodo: `${selectYear}-${selectMonth}`,
          },
        ];
        console.log("Periodo enviado\n", period);
        handleModalEventos(period);
      }
    }
  }
};

const TablaEjecucion = (props) => {
  const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
  const [actualizar, setActualizar] = useState(false);
  const [rows, setRows] = useState([]);
  const [dataDetalle, setDataDetalle] = useState([]);
  const [filteredDataDetalle, setFilteredDataDetalle] = useState([]);
  const [selectRows, setSelectRows] = useState([]);
  const [contract, setContract] = useState([]);
  const [selectMonth, setSelectMonth] = useState([]);
  const [selectYear, setSelectYear] = useState(2021);
  const [selectContract, setSelectContract] = useState([]);
  const [state, setState] = useState(false);
  const [modalEvents, setModalEvents] = useState(false);

  //notificacion
  const [noti, setNoti] = useState(false);
  const [messageNoti, setMessageNoti] = useState("");
  const [severity, setSeverity] = useState("success");

  const showNoti = (_severity, _message) => {
    setSeverity(_severity);
    setMessageNoti(_message);
    setNoti(true);
  }

  const format = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const moneyFormater = (monto) => format.format(monto);

  const handleChangeMonth = (event) => {
    const month = event.target.value;
    setSelectMonth(month);
  };
  const handleChangeYear = (event) => {
    const year = event.target.value;
    setSelectYear(year);
  };

  const handleChangeContract = (event) => {
    setSelectContract(event.target.value);
  };

  const handleModalEventos = (period) => {
    sendPeriod(period);
  };

  const handleChangeChecked = () => {
    setState(!state);
    updateSelectContract(state, setSelectContract);
  };

  //Columns Table
  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "fechafin",
      headerName: "Fecha Fin",
      width: 150,
      editable: false,
      type: "date",
      valueGetter: (params) => `${params.value.slice(0, 10)} `,
    },
    {
      field: "tipoejecucion",
      headerName: "Tipo Ejecución",
      width: 150,
      editable: false,
    },
    {
      field: "periodo",
      headerName: "Periodo",
      width: 150,
      editable: false,
    },
    {
      field: "tipoejecucion",
      headerName: "Estatus Ejecución",
      width: 250,
      editable: false,
    },
    {
      field: "usuario",
      headerName: "Usuario",
      width: 250,
      editable: false,
    },
    {
      field: "detalleError",
      headerName: "Detalle Error",
      width: 750,
      editable: false,
    },
  ];
  //Columnas Tabla detalles
  const columnsDetail = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "IdTableroPeriodoEjecucion",
      headerName: "IdEjecucion",
      width: 150,
      editable: false,
    },
    {
      field: "fechahoraregistro",
      headerName: "Fecha Hora",
      width: 150,
      editable: false,
    },
    {
      field: "cvecontrato",
      headerName: "Contrato",
      width: 150,
      editable: false,
    },
    {
      field: "cvecontratoconvenio",
      headerName: "Convenio",
      width: 150,
      editable: false,
    },
    {
      field: "tipoestado",
      headerName: "Estatus",
      width: 150,
      editable: false,
    },
  ];

  const months = [
    { text: "Enero", value: 1, key: "month-1" },
    { text: "Febrero", value: 2, key: "month-2" },
    { text: "Marzo", value: 3, key: "month-3" },
    { text: "Abril", value: 4, key: "month-4" },
    { text: "Mayo", value: 5, key: "month-5" },
    { text: "Junio", value: 6, key: "month-6" },
    { text: "Julio", value: 7, key: "month-7" },
    { text: "Agosto", value: 8, key: "month-8" },
    { text: "Septiembre", value: 9, key: "month-9" },
    { text: "Octubre", value: 10, key: "month-10" },
    { text: "Noviembre", value: 11, key: "month-11" },
    { text: "Diciembre", value: 12, key: "month-12" },
  ];

  //POST para enviar el periodo
  const sendPeriod = (values, actions) => {
    const [
      { contrato = "", periodo, tipoejecucion = 1, usuario = userInfo.id },
    ] = values;

    const reqParams = new URLSearchParams();
    reqParams.append("periodo", periodo);
    if (contrato.trim()) {
      reqParams.append("contrato", contrato);
    }
    reqParams.append("tipoejecucion", tipoejecucion);
    reqParams.append("usuario", usuario);
    const URL = `${process.env.REACT_APP_MOTOR_URL}/api/motorcalculo?${reqParams.toString()}`;

    const evtSource = new EventSource(URL);
    const tableLogs = document.querySelector("#event-messages");
    if (tableLogs) tableLogs.innerHTML = "";

    evtSource.onopen = function () {
      /* do nothing */
    };

    evtSource.onerror = function () {
      evtSource.close();
    };

    evtSource.onmessage = function (e) {
      const res = JSON.parse(e.data);
      handleOnmessage(tableLogs, modalEvents, setModalEvents, actualizar, setActualizar, res, moneyFormater);
    };

    evtSource.addEventListener("finish", (e) => {
      evtSource.close();
    });

    evtSource.addEventListener("nocontent", (e) => {
      console.log("NO CONTENT", e);
      const res = JSON.parse(e.data);
      showNoti("error", res.message);
    });
  };

  const ejecutarMotor = () => {
    handleEjecutaMotor(selectYear, selectMonth, selectContract, showNoti, handleModalEventos);
  };

  //Obtener datos del servicio
  useEffect(() => {
    ServiceEjecucion.getData()
      .then((response) => {
        let allData = {};
        for (let newData of response) {
          allData[newData.id] = {
            id: newData.id,
            detalleError: newData.detalleError,
            estatusejecucion: newData.estatusejecucion,
            fechafin: newData.fechafin,
            periodo: newData.periodo,
            tipoejecucion: newData.tipoejecucion,
            usuario: newData.usuario,
            acciones: "---",
          };
        }
        const responseFormated = Object.values(allData);
        console.log("response", response);
        console.log("formated:", responseFormated);
        setRows(responseFormated);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
    ServiceEjecucion.getDetailData()
      .then((response) => {
        setDataDetalle(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
    ServiceEjecucion.getContracts()
      .then((response) => {
        setContract(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  }, [actualizar]);

  //Mostrar detalle del row/rows seleccionados
  useEffect(() => {
    let filtered = dataDetalle.filter((d) =>
      selectRows.includes(d.IdTableroPeriodoEjecucion)
    );
    setFilteredDataDetalle(filtered);
  }, [selectRows, dataDetalle]);

  return (
    <div className="ejecuciones-container" style={{ width: "100%" }}>
      <SuccessMessage
        open={noti}
        setNoti={setNoti}
        message={messageNoti}
        setMessageNoti={setMessageNoti}
        severity={severity}
      />
      <ComponentModal
        id="modal-events-calculo"
        title={"Detalles Motor de Cálculo"}
        open={modalEvents}
        onClose={handleModalEventos}
        setStateModal={setModalEvents}
      >
        <div>
          <table className="table-events-calculo">
            <thead>
              <tr>
                <th>Clave convenio</th>
                <th>Concepto/Complemento</th>
                <th>Fecha de pago</th>
                <th>Estatus de proyección</th>
                <th>Monto proyección</th>
              </tr>
            </thead>
            <tbody id="event-messages">

            </tbody>
          </table>
        </div>
      </ComponentModal>
      <div className="header-ejecucion">
        <div className="header-left-ejecucion">
          <FieldText
            labelText="Periodo a Ejecutar"
            type="number"
            id="year"
            name="year"
            min="2010"
            max="2099"
            step="1"
            placeholder="Año"
            value={selectYear}
            onChange={handleChangeYear}
          />
          <Select
            id="months"
            name="mes"
            data={months}
            optV={"value"}
            optL={"text"}
            onChange={handleChangeMonth}
            placeholder={"Mes"}
          />
        </div>

        <div className="header-right-ejecucion">
          <div className="inputText">
            <Checkbox
              className="checbox-ejecuciones"
              checked={state}
              onChange={handleChangeChecked}
              name="checkedA"
              color="primary"
            />
            <label className="label-header-ejecuciones">
              Contrato Convenio
            </label>
          </div>
          <Select
            disabled={!state}
            id="contracts"
            name="contrato"
            data={contract}
            optV={"contratoConvenio"}
            optL={"contratoConvenio"}
            onChange={handleChangeContract}
          />
          <div className="ejecucion-manual">
            <Button id="boton-motor" onClick={ejecutarMotor}>
              <label style={{ width: "100%", font: "11px QuickSand" }}>
                EJECUCIÓN MANUAL
              </label>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <TableData
          columns={columns}
          rows={rows}
          onRowClick={() => {/* do nothing */ }}
          setSelectRows={setSelectRows}
        />
      </div>
      <div style={{ padding: "10px" }}>
        <span className="title-view">Detalle Ejecución</span>
      </div>
      <div style={{ display: selectRows ? "flex" : "none" }}>
        <TableData
          columns={columnsDetail}
          rows={filteredDataDetalle}
          onRowClick={() => {/* do nothing */ }}
          setSelectRows={setSelectRows}
        />
      </div>
    </div>
  );
};

export default TablaEjecucion;
