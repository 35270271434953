// eslint-disable-next-line
import React from "react";

import Radio from "@material-ui/core/Radio";

const RadioField = (props) => {
	return (
		<div role='group' className='form-radio' {...props} >
			<label htmlFor={props.name}>{props.label}</label>
			<div className='radio-options'>
				{props.data.map((ele) => {
					return (
						<label disabled={props.disabled}>
							<Radio
								{...props}
								type='radio'
								name={props.name}
								value={ele.value}
								checked={props.checked == ele.value}
								disabled={props.disabled}
								
							/>
							{ele.nombre}
						</label>
					);
				})}
			</div>
		</div>
	);
};

export default RadioField;
