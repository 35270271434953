import * as React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  label: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "1% !important",
    "& span": {
        color: "#484848 !important",
    font: "normal normal bold 12px Quicksand-Bold !important",
      },
  },
});

export default function CheckLabelForm(props) {
  const classes = useStyles(props);
  return (
    <FormControlLabel
      labelPlacement="start"
      className={classes.label}
      control={
        <Checkbox
          {...props}
          color="primary"
          // onChange={handleChangeCheckValRegEjecucion}
        />
      }
    label={props.label}
    />
  );
}
