import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { ReactComponent as CloseBlack } from "../../assets/icons/close_black_24dp.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const ComponentModal = (props) => {
	//props.title = titulo
	// porps.modal = estado de la vista
	// habdleModal = funcion de abrir y cerrar
	//children = datos del modal
	// setStateModal para cerrar el modal
	return (
		<Dialog
			className={ props.class ? props.class:'dialog-Modal-ejecuciones'}
			open={props.open}
			TransitionComponent={Transition}
			keepMounted
			scroll={'body'}
			onClose={props.handleModal}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'>
			<DialogTitle id='alert-dialog-slide-title'>
				{props.title}
				<span
					className='close-modal'
					onClick={() => {
						props.setStateModal(false);
					}}>
					<SvgIcon>
						<CloseBlack/>
					</SvgIcon>
				</span>
			</DialogTitle>
			<DialogContent  >
				<DialogContentText id='alert-dialog-slide-description'>
					{props.children}
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
};

export default ComponentModal;
