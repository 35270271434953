import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  label: {
    flex: "auto !important",
    margin: " 0px 0px 5px 0px !important",
    color: "#484848",
    font: "normal normal bold 12px Quicksand-Bold",
  },
});

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    font: "normal normal bold 12px Quicksand",
    maxHeight: "31px",
    "& fieldset": {
      borderColor: "#707070",
      top: "0px !important",
      borderRadius: "5px",
      maxHeight: "32px",
      "& legend": {
        display: "none !important",
      },
    },
  },
});

export default function TextFiellToolBar(props) {
  const classes = useStyles(props);
  return (
    <div
      className="inputText"
      style={{
        marginBottom: "10px",
        marginTop: "10px",
      }}
    >
      <label htmlFor={props.name} className={classes.label}>
        {props.labelText}
      </label>
      <CssTextField
        id="input-with-icon-textfield"
        variant="outlined"
        {...props}
        InputProps={{
          startAdornment: <InputAdornment position="start"></InputAdornment>,
        }}
      />
    </div>
  );
}
