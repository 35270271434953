import React from "react";

const AlertError = (props) => {
    //.log(props);
	return (
		<div className='alertMessage'>
			{props.message}
		</div>
	);
};

export default AlertError;
