import { createContext, useState } from 'react';
import InputElementService from '../pages/Administracion/services/InputElementService.service';
import { ComplementService } from '../pages/Contratos/services/complementService';

export const MotorContext = createContext();
export const MotorContextProvider = ({ children }) => {
    const [state, setState] = useState({
        listEntrada: []
    })
    const [userActions, setUserActions] = useState([]);
    const [userInformation, setUserInformation] = useState();
    const [selectRows, setSelectRows] = useState([]);
    const [complements, setComplements] = useState([]);
    const [editEntrada, setEditEntrada] = useState("")
    const [rowView, setRowView] = useState("")
    const [modalViewCampo, setModalViewCampo] = useState(false)
    const [showModalForm, setShowModalForm] = useState(false)
    const [rowReferencia, setRowReferencia] = useState("")
    const [showModalReferencia, setShowModalReferencia] = useState(false)
    const [campo, setCampo] = useState("");
    const [statefuncion, setFuncion] = useState("");
    const [loading, setLoading] = useState(false);
    const [modalConfirmCancelProps, setModalConfirmCancelProps] = useState({
        open: false,
        onClose: () => { setModalConfirmCancelProps({ ...modalConfirmCancelProps, open: false }) },
        title: "",
        onYes: () => {/* do nothing */ },
    });
    const handleModalViewCampo = () => {
        setModalViewCampo(!modalViewCampo)
    }
    const handleModalForm = () => {
        setShowModalForm(!showModalForm)
        setEditEntrada("")
    }
    const handleModalReferencia = () => {
        setShowModalReferencia(!showModalReferencia)
    }
    const handleAddItem = () => {
        obtenerElementosEntrada();
        handleModalForm()
    }
    const handleCampo = (campoNew) => {
        setCampo(campoNew)
    }
    const handleFuncion = (funcion) => {
        setFuncion(funcion)
    }
    const obtenerElementosEntrada = () => {
        InputElementService
            .getElementosEntrada()
            .then((response) => {
                console.log("DATOS DE LA TABLA: ", response)
                setState({
                    listEntrada: response
                })
            })
            .catch((error) => console.log(`Ocurrio un error: ${error}`));
    }

    const GetComplements = (rowId) => {
        ComplementService.GetComplementList(rowId)
            .then((response) => {
                setComplements(response.list);
            })
            .catch((error) => console.log(`Error: ${error}`));
    };

    return (
        <MotorContext.Provider
            value={{
                handleModalForm,
                handleModalViewCampo,
                handleModalReferencia,
                handleAddItem,
                handleCampo,
                handleFuncion,
                obtenerElementosEntrada,
                GetComplements,
                state, setState,
                selectRows, setSelectRows,
                complements, setComplements,
                editEntrada, setEditEntrada,
                rowView, setRowView,
                modalViewCampo, setModalViewCampo,
                showModalForm, setShowModalForm,
                rowReferencia, setRowReferencia,
                showModalReferencia, setShowModalReferencia,
                campo, setCampo,
                statefuncion, setFuncion,
                loading, setLoading,
                modalConfirmCancelProps, setModalConfirmCancelProps,
                userInformation, setUserInformation,
                userActions, setUserActions
            }}
        >
            {children}
        </MotorContext.Provider>
    )
}