import React from "react";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const FieldTextArea = (props) => {
	return (
		<div className='inputTextArea'>
			<label htmlFor={props.name}> {props.labelText} </label>
			<div className='textAreaContainer'>
				<TextareaAutosize
					{...props}
					minRows={2}
				/>
			</div>
		</div>
	);
};

export default FieldTextArea;
