import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormHelperText from "@material-ui/core/FormHelperText";
import ModalCampo from "../ModalCampo/index";
import ModalInput from "../ModalEntrada/index";
import Modal from "react-bootstrap/Modal";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import InputElementService from "../../services/InputElementService.service";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
//Styles
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      height: "32px",
      marginBottom: "3%;",
      fontSize: "12px",
    },
    "& .MuiTextField-root": {
      width: "491px",
    },
    "& .MuiTypography-body1": {
      fontSize: "12px",
      fontFamily: "Quicksand-Bold",
    },
    "& textarea": {
      width: "40ch",
    },
    "& #funcion-select": {
      width: "491px",
    },
    "& #tipo-valor": {
      margin: theme.spacing(1),
      width: "40ch",
      padding: "11px 18px;",
    },
    "& .MuiSelect-select": {
      width: "395px",
      display: "flex",
      alignSelf: "flex-end",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    "& .MuiButton-root": {
      minWidth: "40px",
    },
  },
}));

const CssSelect = styled(Select)({
  maxHeight: "31px",
  color: "#575757",
  font: "normal normal bold 12px Quicksand",
  "& fieldset": {
    border: "1px solid #707070",
  },
});

const CssTextField = styled(TextField)({
  minWidth: "70%",
  "& .MuiOutlinedInput-root": {
    font: "normal normal bold 12px Quicksand",
    padding: "0px",
    maxHeight: "31px",
    "& fieldset": {
      borderColor: "#707070",
      top: "0px !important",
      borderRadius: "5px",
      maxHeight: "32px",
    },
  },
});

const CssTextareaAutosize = styled(TextareaAutosize)({
  minWidth: "80%",
  margin: "0px",
  font: "normal normal 12px Quicksand",
  color: "#484848",
  borderRadius: "5px",
  padding:"10px",
  "& .MuiOutlinedInput-root": {
    padding: "0px",
    "& fieldset": {
      color: "#707070",
      borderColor: "#707070",
    },
  },
});

const handleChangeTipoValor = (value, data, setData, valid, setValid) => {
    if (value === "Funcion") {
      // FUNCION
      setData({
        ...data,
        tipovalorfijo: false,
        tipovalordato: "",
        valor: "",
        radioValue: value,
      });
      setValid({ ...valid, valor: false, valorFijo: false });
    } else {
      //VALOR FIJO
      setData({
        ...data,
        tipovalorfijo: false,
        funcion: "",
        valor: "",
        radioValue: value,
      });
      setValid({ ...valid, funcion: false });
    }
};

const validaNoNativo = (data, setValid) => {
  let formValid = true;
  let alias = false;
  let valor = false;
  let valorFijo = false;
  let funcion = false;
  if (!data.alias) {
    alias = true;
    formValid = false;
  }
  if (data.radioValue === "Fijo") {
    if (!data.valor) {
      valor = true;
      formValid = false;
    }
    if (!data.tipovalorfijo) {
      valorFijo = true;
      formValid = false;
    }
  } else {
    //Funcion
    if (!data.funcion) {
      funcion = true;
      formValid = false;
    }
  }
  setValid({
    alias,
    valor,
    funcion,
    valorFijo,
  });
  return formValid;
};

const validaNativo = (data, setValid) => {
  let formValid = true;
  let alias = false;
  let nombre = false;
  if (!data.alias) {
    alias= true;
    formValid = false;
  } else if (data.nombre === "") {
    nombre = true;
    formValid = false;
  }
  setValid({
    alias,
    nombre
  });
  return formValid;
};

const handleAddCampoEntrada = (edit, data, setValid, handleUpdateEntrada, handleSaveEntrada) => {
  const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
  if (edit) {
    if (data.aplicanativo === "Si") {
      if (validaNativo(data, setValid)) {
        let nombre = data.nombre.split(".");
        handleUpdateEntrada({
          ...data,
          campo: nombre[1],
          tabla: nombre[0],
        });
      }
    } else if (validaNoNativo(data, setValid)){
        handleUpdateEntrada(data);
    }
  } else {
    if (data.aplicanativo === "Si") {
      if (validaNativo(data, setValid)) {
        let nombre = data.nombre.split(".");
        const values = {
          alias: data.alias,
          campo: nombre[1],
          tabla: nombre[0],
          funcion: data.funcion,
          tipovalorfijo: data.tipovalorfijo,
          aplicanativo: data.aplicanativo,
          valor: data.valor,
          tipoestado: 1,
          usuariocreacion: userInfo.id,
          descripcion: data.descripcion,
          nombre: data.nombre,
          usuarioultimamodificacion: userInfo.id,
        };
        handleSaveEntrada(values);
      }
    } else if (validaNoNativo(data, setValid)) {
        const values = {
          alias: data.alias,
          funcion: data.funcion,
          tipovalorfijo: data.tipovalorfijo,
          tipovalordato: data.tipovalordato,
          aplicanativo: data.aplicanativo,
          valor: data.valor,
          tipoestado: 1,
          usuariocreacion: userInfo.id,
          descripcion: data.descripcion,
          nombre: data.nombre,
          usuarioultimamodificacion: userInfo.id,
        };
        handleSaveEntrada(values);
    }
  }
};

const updateCampo = (campo, disableFunction, data, setData, setValid) => {
  if (disableFunction) {
    setData({...data,nombre:campo,funcion:""});
    setValid({nombre:false,funcion:false});
  } else {
    setData({...data,nombre:campo});
    setValid({nombre:false});
  }    
};

const loadData = (props, data, setEdit, setData, setState, getCamposReferencia) => {
  if (props.editEntrada) {
    setEdit(true);
    //Validar si tiene campo entrada referencia
    if (props.editEntrada.id !== data.id) {
      setData({
        alias: props.editEntrada.alias,
        aplicanativo: props.editEntrada.aplicanativo,
        descripcion: props.editEntrada.descripcion,
        // fechacreacion: Date.now(),
        funcion: props.editEntrada.funcion,
        id: props.editEntrada.id,
        nombre: props.editEntrada.nombre,
        tipoestado: props.editEntrada.tipoestado,
        tipodato: props.tipodato
          ? props.tipodato
          : props.editEntrada.tipodato,
        tipovalorfijo: props.editEntrada.tipovalorfijo,
        tipovalordato: props.editEntrada.tipovalordato,
        usuariocreacion: props.editEntrada.usuariocreacion,
        usuarioultimamodificacion:
          props.editEntrada.usuarioultimamodificacion,
        valor: props.editEntrada.valor,
        radioValue: props.editEntrada.funcion ? "Funcion" : "Fijo",
      });
      if (props.editEntrada.aplicanativo === "Si") {
        setState({
          checkedA: true,
        });
        getCamposReferencia(props.editEntrada.id);
      } else {
        setState({
          checkedA: false,
        });
      }
    }
  } else {
    setEdit(false);
  }
};

const updateData = (checked, data, setData) => {
  if (checked) {
    setData({
      ...data,
      aplicanativo: "Si"
    });
  } else {
    setData({
      ...data,
      aplicanativo: "No"
    });
  }
}

const InputFrom = (props) => {
  const classes = useStyles();
  const [modalCampo, setModalCampo] = useState(false);
  const [modalEntrada, setModalEntrada] = useState(false);
  const [edit, setEdit] = useState(false);
  const [camposReferencia, setCamposReferencia] = useState([]);
  const [funcionesNativas, setFuncionesNativas] = useState([]);
  const [funcionesNoNativas, setFuncionesNoNativas] = useState([]);
  const [tipoValor, setTipoValor] = useState([]);

  const getFuncionesNativas = async () => {
    const dataFunciones = await InputElementService.getEntidadValorMultiple({nombretabla:'CampoEntrada', nombrecampo:'Funcion'});
    setFuncionesNativas(dataFunciones);
  };

  const getFuncionesNoNativas = async () => {
    const dataFuncionesNN = await InputElementService.getEntidadValorMultiple({nombretabla:'CampoEntrada', nombrecampo:'FuncionNoNativo'});
    setFuncionesNoNativas(dataFuncionesNN);
  };

  const getTipoValor = async () => {
    const dataTV = await InputElementService.getEntidadValorMultiple({nombretabla:'CampoEntrada', nombrecampo:'TipoValor'});
    setTipoValor(dataTV);
  };

  const getCamposReferencia = async (idElementoEntrada) => {
    const dataCR = await InputElementService.getCamposReferencia(
      idElementoEntrada
    );
    setCamposReferencia(dataCR);
  };

  useEffect(() => {
    getFuncionesNativas();
    getFuncionesNoNativas();
    getTipoValor();
  }, []);

  const initData = {
    alias: "",
    aplicanativo: "Si",
    descripcion: "",
    // fechacreacion: Date.now(),
    fechaultimamodificacion: null,
    funcion: null,
    id: null,
    nombre: "",
    radioValue: "Funcion",
    tabla: "",
    tipodato: "",
    tipoestado: null,
    tipovalorfijo: false,
    usuariocreacion: "",
    usuarioultimamodificacion: "",
    valor: "",
  };

  const [data, setData] = useState(initData);

  const [valid, setValid] = useState({
    alias: false,
    tipovalordato: false,
    funcion: false,
    valor: false,
    nombre: false,
  });

  const [state, setState] = useState({
    checkedA: true,
  });

  const handleUpdateCampo = (campo, disableFunction) => {
    updateCampo(campo, disableFunction, data, setData, setValid);  
  };

  useEffect(() => {
    loadData(props, data, setEdit, setData, setState, getCamposReferencia);
  }, [
    props.editEntrada,
    data.id,
    props.tipodato,
  ]);

  const handleModalCampo = () => {
    setModalCampo(!modalCampo);
  };
  const handleModalEntrada = () => {
    setModalEntrada(!modalEntrada);
  };
  const handleChangeRadio = (event) => {
    handleChangeTipoValor(event.target.value, data, setData, valid, setValid);
  };

  const handleChangeFrom = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    setValid({ ...valid, [event.target.name]: false });
  };

  const resetForm = () => {
    setData(initData);
    setState({
      checkedA: true,
    });
  };
  
  const handleUpdateEntrada = (editDataEntrada) => {

    InputElementService.postUpdateElementosEntrada(editDataEntrada)
      .then((response) => {        
        props.handleAddItem();
        props.showNoti("Elemento Guardado");
        resetForm();
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  const handleAddItem = (event) => {
    event.preventDefault();
    handleAddCampoEntrada(edit, data, setValid, handleUpdateEntrada, handleSaveEntrada);
  };

  const handleSaveEntrada = (dataEntrada) => {
    InputElementService.postNewElementosEntrada(dataEntrada)
      .then((response) => {        
        props.showNoti("Elemento Guardado");
        props.handleAddItem();
        resetForm();
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };
  const handleChange = (event) => {
    setState({ [event.target.name]: event.target.checked });
    updateData(event.target.checked, data, setData);
  };
  const handleChangeSelec = (event) => {
    setData({
      ...data,
      tipovalorfijo: true,
      tipodato: "",
      [event.target.name]: event.target.value,
    });
    setValid({ ...valid, funcion: false, valorFijo: false });
  };

  const handleChangeFunction = (event) => {
    setData({ ...data, funcion: event.target.value });
    setValid({ ...valid, funcion: false, valorFijo: false });
  };

  const handleCloseModal = () => {
    resetForm();
    props.handleModalForm();
  };

  return (
    <Modal
      dialogClassName={styles.modal}
      show={props.showModalForm}
      onHide={handleCloseModal}
    >
      <Modal.Body style={{ padding: "14px" }}>
        <div className={classes.root}>
          <div className={styles.headerModal}>
            <div className={styles.headerLeftModal}>
              <span className="title-view">
                {state.checkedA
                  ? "Campo Entrada Nativo"
                  : "Campo Entrada No Nativo"}
              </span>
            </div>
            <div className={styles.headerRigthModal}>
              <Button
                className="add-button"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                <CloseIcon />
              </Button>
            </div>
          </div>
          <form onSubmit={handleAddItem} className={styles.contFrom}>
            <div className={styles.gridContainer}>
              <FormControlLabel
                style={{
                  display: "flex",
                  width: "146px",
                  justifySelf: "start",
                  alignSelf: "start",
                }}
                control={
                  <Checkbox
                    checked={state.checkedA}
                    onChange={handleChange}
                    name="checkedA"
                    color="primary"
                    disabled={edit}
                  />
                }
                label="Aplica nativo"
              />              
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label className={styles.label}> Alias: </label>
                <CssTextField
                  error={valid.alias}
                  inputProps={{ maxLength: 100 }}
                  name="alias"
                  value={data.alias}
                  onChange={handleChangeFrom}
                  variant="outlined"
                  helperText={
                    valid.alias ? "Por favor agregue un valor para alias." : ""
                  }
                />
              </div>
              <div
                style={{
                  display: state.checkedA ? "flex" : "none",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  
                }}
              >
                <label className={styles.label}> Campo: </label>
                <CssTextField
                  style={{ marginLeft: "7px", width: "460px" }}
                  error={valid.nombre}
                  value={data.nombre}
                  id="outlined-basic"
                  disabled
                  variant="outlined"
                  helperText={
                    valid.nombre ? "Por favor agregue un valor para campo." : ""
                  }
                />
                <span
                  className={styles.campoButton}
                  onClick={() =>
                    !camposReferencia.length && setModalCampo(true)
                  }
                  style={{
                    maxHeight:"31px",
                    marginTop:"0px",
                    paddingTop:"1px",
                    border: "1px solid #707070",

                  }}
                >
                  <MoreHorizIcon
                    style={{
                      color: "#434343",
                      cursor: "pointer",
                      marginTop: "inherit"
                    }}
                  />
                </span>
              </div>
              <div style={{ display: state.checkedA ? "flex" : "none" }}></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label className={styles.label}> Descripción: </label>
                <CssTextareaAutosize
                  style={{ width: "491px", height: "114px" }}
                  maxLength="400"
                  value={data.descripcion}
                  onChange={handleChangeFrom}
                  name="descripcion"
                  aria-label="minimum height"
                  minRows={2}
                />
              </div>
              <div
                style={{
                  display: state.checkedA ? "flex" : "none",
                  marginTop: "2%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label className={styles.label}> Función: </label>
                <FormControl
                  style={{}}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <CssSelect
                    style={{ width: "491px" }}
                    native
                    value={data.funcion}
                    error={valid.funcion}
                    onChange={handleChangeFunction}
                    label="selecciona una funcion"
                    inputProps={{
                      name: "age",
                      id: "funcion-select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {funcionesNativas.map((ele) => {
                      return <option value={ele.nombrevalor}> {ele.nombrevalor}</option>;
                    })}                    
                  </CssSelect>
                  {valid.funcion ? (
                    <FormHelperText>
                      Por favor agregue un valor para función.
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </div>
              <div
                style={{
                  width: "100%",
                  display: state.checkedA ? "none" : "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      value={data.radioValue}
                      name="radioValue"
                      onChange={handleChangeRadio}
                    >
                      <FormControlLabel
                        name="tipo"
                        value="Fijo"
                        control={<Radio />}
                        label="Valor fijo"
                      />
                      <FormControlLabel
                        style={{}}
                        name="tipo"
                        value="Funcion"
                        control={<Radio />}
                        label="Valor función"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    Tipo valor{" "}
                  </label>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <CssSelect
                      native
                      disabled={data.radioValue === "Funcion"}
                      name="tipovalordato"
                      onChange={handleChangeSelec}
                      value={data.tipovalordato}
                    >
                      <option aria-label="None" value="" />
                      {tipoValor.map((ele) => {
                        return <option value={ele.nombrevalor}> {ele.nombrevalor}</option>;
                      })}   
                    </CssSelect>
                    {valid.valorFijo ? (
                      <FormHelperText>
                        Por favor agregue un valor para tipo valor.
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <CssSelect
                      native
                      disabled={data.radioValue === "Fijo"}
                      value={data.funcion}
                      onChange={handleChangeFunction}
                      label="selecciona una funcion"
                      inputProps={{
                        name: "funcion",
                        id: "outlined-funcion-native-simple",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {funcionesNoNativas.map((ele) => {
                        return <option value={ele.nombrevalor}> {ele.nombrevalor}</option>;
                      })}   
                    </CssSelect>
                    {valid.funcion ? (
                      <FormHelperText>
                        Por favor agregue un valor para tipo valor.
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label className={styles.label}> Valor: </label>
                <CssTextField
                  value={data.valor}
                  name="valor"
                  error={valid.valor}
                  helperText={valid.valor ? "Por favor agregue un valor." : ""}
                  onChange={handleChangeFrom}
                  variant="outlined"
                />
              </div>
            </div>
            <ModalCampo
              handleModalCampo={handleModalCampo}
              modalCampo={modalCampo}
              handleUpdateCampo={handleUpdateCampo}
            />
            <ModalInput
              handleModalEntrada={handleModalEntrada}
              modalEntrada={modalEntrada}
              handleFuncion={props.handleFuncion}
            />
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ padding: "0px", borderTop: "0px" }}>
        <div className={styles.saveCont}>
          <div className={styles.modalButtons}>
            <Button className={styles.saveButton} onClick={handleAddItem}>
              Guardar
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default InputFrom;
