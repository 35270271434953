import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  label: {
    minWidth: "30%",
    maxWidth: "40%",
    // backgroundColor: "red",
    // color: (props) => props.color,
    // marginBottom: "2rem !important",
    // margin: " 0px 0px 0px 0px !important",
    // margin: " 0px 0px 15px 0px !important",
    color: "#484848",
    font: "normal normal bold 12px Quicksand-Bold",
  },
});

// .MuiOutlinedInput-notchedOutline {
//   border-color: $borderInput;
//   height: 32px;
//   // top: 10px;
// }
const CssTextField = styled(TextField)({
  minWidth: "60%",
  maxWidth: "60%",
  // marginLeft: "4px",
  "& .MuiOutlinedInput-root": {
    font: "normal normal bold 12px Quicksand",
    // padding: "0px",
    maxHeight: "31px",
    "& fieldset": {
      borderColor: "#707070",
      // margin: "3px",
      top: "0px !important",
      borderRadius: "5px",
      maxHeight: "32px",
      // minHeight: "27px",
      "& legend": {
        display: "none !important",
      },
    },
    // "&.Mui-focused fieldset": {
    //   borderColor: "#a1191e",
    // },
  },
});

export default function TextFieldModalForm(props) {
  const classes = useStyles(props);
  return (
    <div className="inputText" style={{
      marginBottom:"10px",
      marginTop:"10px",
    }}>
      <label htmlFor={props.name} className={classes.label}>
        {props.labelText}
      </label>
      <CssTextField
        id="input-with-icon-textfield"
        variant="outlined"
        {...props}
        InputProps={{
          startAdornment: <InputAdornment position="start"></InputAdornment>,
        }}
      />
    </div>
  );
}
