import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import * as React from "react";

const CustomizedTabStyle = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
    borderRadius: "15px!important",
    minHeight: "43px !important",
    height: "43px !important",
    margin: "0px",
    font: "12px/15px Quicksand-Bold",
		textTransform: "uppercase",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "#8A8A8D",
    "&.MuiTab-labelIcon": {
      paddingTop: 3,
    },
    "&.Mui-selected": {
      backgroundColor: "#C32032 !important",
      color: "#FFFFFF !important",
      opacity: "1 !important",
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

export default function CustomizedTab(props) {
  return (
    <>
      <CustomizedTabStyle {...props} />
    </>
  );
}
