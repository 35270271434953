import API from "Services/API";

const ComplementService = {
  Delete: (params) =>
    new Promise((resolve, reject) => {
      API.post("/api/Complement/List/Delete", params )
        .then((res) => res.data)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    }),
  GetComplementList: (rowId) =>
    new Promise((resolve, reject) => {
      API.get(
        "/api/Complement/List/" + rowId
      )
        .then((res) => res.data)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    }),
  SaveComplement: (params) =>
    new Promise((resolve, reject) => {
      API.post("/api/Complement/", params)
        .then((res) => res.data)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    }),
};

export { ComplementService };
