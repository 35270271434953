import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const SuccessMessage = (props) => {
	const handleClose = () => {
		props.setNoti(false);
		props.setMessageNoti("");
	};

	return (
		<div>
			{props.open &&
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={props.open}
					onClose={handleClose}
					key={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={6000}>
					<Alert
						onClose={() => {
							handleClose();
						}}
						severity={props.severity ? props.severity : 'success'}>
						{props.message}
					</Alert>
				</Snackbar>
			}
		</div>
	);
};
export default SuccessMessage;
