import * as React from "react";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  label: {
    minWidth: "40%",
    maxWidth: "40%",
    margin: " 0px 0px 10px 0px !important",
    color: "#484848",
    font: "normal normal bold 12px Quicksand-Bold",
  },
});

const CssSelect = styled(Select)({
  minWidth: "60%",
  maxWidth: "60%",
  paddingBottom: "0px",
  paddingTop: "0px",
  maxHeight: "31px",
  minHeight: "31px",
  color: "#575757",
  font: "normal normal bold 12px Quicksand",
  "& fieldset": {
    border: "1px solid #707070",
    maxHeight: "31px",
    "& legend": {
      display: "none !important",
    },
  },
});

export default function SelectModalForm(props) {
  const classes = useStyles(props);
  const {data, optV, optL, labelText, ...rest} = props;
  return (
    <div
      className="inputText"
      style={{
        marginBottom: "10px",
        marginTop: "10px",
      }}
    >
      <label htmlFor={props.name} className={classes.label}>
        {labelText}
      </label>

      <CssSelect
        className="select"
        variant="outlined"
        native
        {...rest}
      >
        <option aria-label="None" value="">
          ---Seleccionar---
        </option>
        {data.map((ele) => {
          return <option value={ele[optV]}> {ele[optL]}</option>;
        })}
      </CssSelect>
    </div>
  );
}
