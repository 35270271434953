import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@material-ui/core/Button";
import { MotorContext } from "../../../../context/MotorContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 370,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
  headerModal: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  buttonNo: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#434343",
    fontFamily: "Quicksand",
    fontSize: 14,
    color: "#434343",
    width: 84,
    borderRadius: 0,
  },
  buttonYes: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#434343",
    background: "#434343",
    color: "#FFFFFF",
    fontFamily: "Quicksand",
    fontSize: 14,
    width: 84,
    borderRadius: 0,
  },
  botones: {
    display: "flex",
    justifyContent: "space-around",
  },
  title: {
    textAlign: "center",
    fontFamily: "Futura-Medium",
    fontSize: "20px",
    color: "#C32032",
    opacity: 1,
    marginBottom: "25px",
  },
}));

const ModalConfirmCancel = () => {
  const classes = useStyles();

  const {
    modalConfirmCancelProps
  } = useContext(MotorContext);

  return (
    <Modal
      open={modalConfirmCancelProps.open}
      onClose={modalConfirmCancelProps.onClose}
      aria-labelledby="simple-modal-title"
    >
      <div className={classes.paper}>
        <div className={classes.headerModal}>
          <div style={{ position: "absolute", right: "0px", top: "0px" }}>
            <Button
              className="add-button"
              onClick={() => {
                modalConfirmCancelProps.onClose();
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
        <div className={classes.title}>
          <span id="simple-modal-title">
            {modalConfirmCancelProps.title
              ? modalConfirmCancelProps.title
              : "¿Desea cancelar la operación?"}
          </span>
        </div>
        <div className={classes.botones}>
          <div>
            <Button
              className={classes.buttonNo}
              onClick={() => {
                modalConfirmCancelProps.onClose();
              }}
            >
              NO
            </Button>
          </div>
          <div>
            <Button
              className={classes.buttonYes}
              onClick={() => {
                modalConfirmCancelProps.onYes();
                modalConfirmCancelProps.onClose();
              }}
            >
              SI
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirmCancel;
