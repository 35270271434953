
import React from 'react'
import styles from './index.module.css'
import Modal from 'react-bootstrap/Modal'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { FcCheckmark } from 'react-icons/fc';
import { AiOutlineClose } from 'react-icons/ai';

import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const ModalEntrada = (props) => {

  const [state, setState] = React.useState({
    funcionFilter: "",
    funciones: [
      {
        "nombre": "ObtenerFecha()"
      },
      {
        "nombre": "ObtenerPeriodo()"
      },
      {
        "nombre": "ObtenerAño()"
      },
      {
        "nombre": "ObtenerMes()"
      }
    ]
  });

  const handleChange = (event) => {
    setState({
      ...state, [event.target.name]: event.target.value
    })
    // console.log("Estado en Modal entrada: ",state)
  };

  return (
    <>

      <Modal size="lg" show={props.modalEntrada} onHide={props.handleModalEntrada} >
        <Modal.Header closeButton>
          <Modal.Title>Selección de funciones nativos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form >

            <div className={styles.radioGroup}>
              <FormControl component="fieldset">
                <label >Funciones</label>
                <RadioGroup aria-label="gender" name="gender1" value={state.value} onChange={handleChange}>

                  {
                    state.funciones.map((element, index) => {
                      if (state.funciones.length > 1) {

                        return (
                          <FormControlLabel key={index} name="funcionFilter" value={element.nombre} control={<Radio />} label={element.nombre} />
                        )

                      }


                    })
                  }

                </RadioGroup>
              </FormControl>


            </div>
          </form>
        </Modal.Body>
        <Modal.Footer >
          <span
            onClick={() => { props.handleModalEntrada() }}
          >
            <AiOutlineClose
              style={{ fontSize: "4vw", cursor: "pointer", color: "red" }}
            />
          </span>
          <span
            onClick={() => { props.handleModalEntrada(); props.handleFuncion(state.funcionFilter) }}
          >
            <FcCheckmark
              style={{ fontSize: "4vw", cursor: "pointer" }}
            />
          </span>


        </Modal.Footer>
      </Modal>
    </>
  )


}


export default ModalEntrada