import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import AddBoxIcon from "@material-ui/icons/AddBox";
//Icons
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TuneIcon from "@material-ui/icons/Tune";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import SelectField from "componets/Custom/Input/Select/SelectModalForm";
import AlertError from "componets/ErrorMessage";
import RadioField from "componets/Input/RadioField";
//Custom Components
import ComponentModal from "componets/Modal";
import SuccessMessage from "componets/SuccessMessage";
import TableData from "componets/TableData/index";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import FieldText from "../../../../componets/Custom/Input/TextField/TextFieldModalForm";
import FieldTextMultiline from "../../../../componets/Custom/Input/TextField/TextFieldMultilineModalForm";
import ModalDelete from '../../../../componets/Custom/Utils/ModalDelete';
import {
  estado,
  logicOperatorList,
  relationalOperatorList
} from "../../../../componets/DataSelect/DataArray";
//Services
import ServiceLogica from "../../../../Services/ServiceLogica.service";
import InputElementService from "../../services/InputElementService.service";
import { MotorContext } from "context/MotorContext";


//User Data
const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
const dateYMD = moment().format();
const deleteItem = (selectRows,handleOpenModalDelete, showNoti) => {
  if (selectRows.length) {
      handleOpenModalDelete();
  } else {
      showNoti('seleccione un elemento de la tabla','error');
  }
};
const deleteDetalleItem = (selectRowsDetalle, handleOpenModalDeleteDetalle, showNoti) => {
  if (selectRowsDetalle.length) {
      handleOpenModalDeleteDetalle();
  } else {
      showNoti('seleccione un elemento de la tabla','error');
  }
}
const handleModalEditar = (data, setRowEditData, actualizarRowsDetalle, setStateModalEdit, stateModalEdit) => {
  if (data) {
    setRowEditData(data);
    actualizarRowsDetalle(data.id);
  } else {
    setRowEditData("");
    document.getElementById("logica").reset();
  }
  setStateModalEdit(!stateModalEdit);
};
const handleModalDetalles = (data, setTitleDetailModal, setRowEditData, actualizarRowsDetalle, setModalDetalles, modalDetalles) => {
  if (data) {
    let nombre = data.nombre;
    setTitleDetailModal(nombre);
    setRowEditData(data);
    actualizarRowsDetalle(data.id);
  } else {
    setRowEditData("");
    document.getElementById("logica").reset();
  }
  setModalDetalles(!modalDetalles);
};

const handleModalDetalleLogico = (data, setRowEditDetalle, setModalDetalleLogico, modalDetalleLogico) => {
  if (data) {
    setRowEditDetalle(data);
  } else {
    setRowEditDetalle("");
    document.getElementById("logicaDetalle").reset();
  }
  setModalDetalleLogico(!modalDetalleLogico);
};

const handleModalDetalle = (data, setRowEditDetalle, setStateModalDetalle, stateModalDetalle) => {
  if (data) {
    setRowEditDetalle(data);
  } else {
    setRowEditDetalle("");
    document.getElementById("logicaDetalle").reset();
  }
  setStateModalDetalle(!stateModalDetalle);
};

const fiendEE = (listData, value) => {
  const data = listData.find((ele) => ele.id === value);
  return data.alias || data.nombre;
};

const submit = (values, rowEditDetalle, editDataDetalle, addDataDetalle) => {
  if (rowEditDetalle) {
    editDataDetalle(values, null);
  } else {
    addDataDetalle(values, null);
  }
};

const getTitle = (rowEditData) => {
  return rowEditData ? "Editar Registro" : "Crear Registro";
};

const getButtonText = (rowEditData) => {
  return rowEditData != "" ? "Guardar" : "Crear";
}

const ConfigLogica = (props) => {
  const [rows, setRows] = useState([]);
  const [selectRows, setSelectRows] = useState([]); //rows seleccioandas
  const [actualizar, setActualizar] = useState(false);
  //Modal Logica
  const [stateModalEdit, setStateModalEdit] = useState(false);
  const [rowEditData, setRowEditData] = useState([]); //celda modificada
  //Modal detalle logica
  const [rowsDetalle, setRowsDetalle] = useState([]);
  const [dataDetalle, setDataDetalle] = useState([]);
  const [stateModalDetalle, setStateModalDetalle] = useState(false);
  const [selectRowsDetalle, setSelectRowsDetalle] = useState([]); //rows seleccioandas
  const [modalDetalles, setModalDetalles] = useState(false); //Para el modal de detalles unicamente
  const [modalDetalleLogico, setModalDetalleLogico] = useState(false); //Para el segundo modal de detalle
  const [rowEditDetalle, setRowEditDetalle] = useState([]); //celda modificada
  //editar
  const [disabled, setDisabled] = useState(true);
  const [disabledDetalle, setDisabledDetalle] = useState(true);
  //opciones elementos de entrada
  const [optionsEE, setOptionsEE] = useState([]);
  //notificacion
  const [noti, setNoti] = useState(false);
  const [messageNoti, setMessageNoti] = useState("");

  const [titleDetailModal, setTitleDetailModal] = useState("");

  //-------------------Confirmacion-------------------
  const [modalConfirmacion, setModalConfirmacion] = useState(false);
  const [modalConfirmacionDetalle, setModalConfirmacionDetalle] =
    useState(false);

  const handleConfirmation = () => {
    setModalConfirmacion(!modalConfirmacion);
  };

  const handleConfirmationDetalle = () => {
    setModalConfirmacionDetalle(!modalConfirmacionDetalle);
  };

  const [severity, setSeverity] = useState("success");
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [openModalDeleteDetalle, setOpenModalDeleteDetalle] = React.useState(false);
  const {
    modalConfirmCancelProps, setModalConfirmCancelProps
  } = useContext(MotorContext);

  const handleConfirmClose = () => {
    setModalConfirmCancelProps({
      ...modalConfirmCancelProps,
        open: true,
        onYes: setStateModalEdit,
    });
  }

  const handleConfirmCloseDetalle = () => {
    setModalConfirmCancelProps({
      ...modalConfirmCancelProps,
        open: true,
        onYes: setStateModalDetalle,
    });
  }
  

  const handleOpenModalDelete = () => {
      setOpenModalDelete(true);
  };

  const handleCloseModalDelete = () => {
      setOpenModalDelete(false);
  };

  const handleDeleteItem = () => {
    deleteItem(selectRows,handleOpenModalDelete, showNoti);
  }

  const handleOpenModalDeleteDetalle = () => {
    setOpenModalDeleteDetalle(true);
  };

  const handleCloseModalDeleteDetalle = () => {
      setOpenModalDeleteDetalle(false);
  };

  const handleDeleteDetalleItem = () => {
    deleteDetalleItem(selectRowsDetalle, handleOpenModalDeleteDetalle, showNoti);
  }

  const showNoti = (mensaje, severityNoti) => {
    setMessageNoti(mensaje);
    setSeverity(severityNoti);
    setNoti(true);        
  }
  ///-------------------------------------------------

  const handleModalEdit = (data) => {
    handleModalEditar(data, setRowEditData, actualizarRowsDetalle, setStateModalEdit, stateModalEdit);
  };
  //Modal detalles unicamente
  const handleModalDetails = (data) => {
    handleModalDetalles(data, setTitleDetailModal, setRowEditData, actualizarRowsDetalle, setModalDetalles, modalDetalles);
  };

  const handleModalLogicDetail = (data) => {
    handleModalDetalleLogico(data, setRowEditDetalle, setModalDetalleLogico, modalDetalleLogico);
  };

  const handleModalDetail = (data) => {
    handleModalDetalle(data, setRowEditDetalle, setStateModalDetalle, stateModalDetalle);
  };

  const validationDetalle = Yup.object().shape({
    nombre: Yup.string().required("Por favor agregue un valor para Nombre."),
    idcampoorigen: Yup.string().required(
      "Por favor agregue un valor para Campo origen."
    ),
    idcampodestino: Yup.string().required(
      "Por favor agregue un valor para Campo destino."
    ),
    operadorrelacional: Yup.string().required(
      "Por favor agregue un valor para Operador relacional."
    ),
    operadorlogico: Yup.string().required(
      "Por favor agregue un valor para Operador lógico."
    ),
  });
  //Validacion del formulario
  const validation = Yup.object().shape({
    nombre: Yup.string().required("Por favor agregue un valor para Nombre."),
    descripcion: Yup.string().required(
      "Por favor agregue un valor para Descripcion."
    ),
    tipoestado: Yup.string().required(
      "Por favor agregue un valor para Tipo de estado."
    ),
  });

  //Columnas de la tabla
  const columns = [
    { field: "id", headerName: "ID", width: 60, minWidth: 60 },
    {
      field: "nombre",
      headerName: "Nombre",
      minWidth: 400,
      editable: false,
    },
    {
      field: "descripcion",
      headerName: "Descripción",
      minWidth: 450,
      editable: false,
    },
    {
      field: "acciones",
      headerName: " ",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <strong>
          <div className="column-actions">
            <Button
              style={{ minWidth: "18px" }}
              className="add-button"
              onClick={() => {
                setDisabled(false);
                setDisabledDetalle(false);
                handleModalEdit(params.row);
              }}
            >
              <EditIcon />
            </Button>

            <Button
              style={{ minWidth: "18px" }}
              className="add-button"
              onClick={() => {
                setDisabled(true);
                setDisabledDetalle(true);
                handleModalDetails(params.row);
              }}
            >
              <TuneIcon />
            </Button>
          </div>
        </strong>
      ),
      filterable: false
    },
  ];
  //VisibilityIcon
  //Columna de detalle
  const columnsDetalle = [
    {
      field: "nombre",
      headerName: "Nombre",
      width: 280,
      editable: false,
    },
    {
      field: "operadorlogico",
      headerName: "Operador lógico",
      width: 150,
      editable: false,
    },
    {
      field: "idcampoorigen",
      headerName: "Campo",
      width: 280,
      editable: false,
      valueGetter: (params) => fiendEE(optionsEE, params.value),
    },
    {
      field: "operadorrelacional",
      headerName: "Operador relacional",
      width: 160,
      editable: false,
    },
    {
      field: "idcampodestino",
      headerName: "Valor",
      width: 280,
      editable: false,
      valueGetter: (params) => fiendEE(optionsEE, params.value),
    },
    {
      field: "acciones",
      headerName: "Acciones",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <strong>
          <div className="column-actions">
            <Button
              className="add-button"
              onClick={() => {
                setDisabledDetalle(false);
                handleModalDetail(params.row);
              }}
            >
              <EditIcon />
            </Button>
          </div>
        </strong>
      ),
      filterable: false
    },
  ];

  //Actualizacion de datos de la tabla
  useEffect(() => {
    ServiceLogica.getData()
      .then((response) => {
        setRows(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));

    ServiceLogica.getDataDetalle()
      .then((response) => {
        setDataDetalle(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
    InputElementService.getElementosEntrada()
      .then((response) => {
        setOptionsEE(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  }, [actualizar]);

  useEffect(() => {
    actualizarRowsDetalle(rowEditData.id);
  }, [dataDetalle]);

  //data detalle
  const actualizarRowsDetalle = (id) => {
    const newData = findElementDetalle(dataDetalle, id);
    setRowsDetalle(newData);
  };
  //Agregar datos
  const addData = (values, actions) => {
    values.usuariocreacion = userInfo.id;
    ServiceLogica.addData(values)
      .then((response) => {
        setActualizar(!actualizar);
        handleModalEdit();
        showNoti("Elemento creado", "success")
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  //Buscar elemento en la lista de datos
  const findElement = (listData, id) => {
    return listData.find((ele) => ele.id === id);
  };

  const findElementDetalle = (listData, id) => {
    return listData.filter((ele) => ele.idtablerologico === id);
  };

  //Eliminar datos
  const deleteData = () => {
    selectRows.map((ele) => {
      const rowelement = findElement(rows, ele);


      return ServiceLogica.deleteData(rowelement.id)
        .then((response) => {
          setActualizar(!actualizar);
          setSelectRows([]);
          response.error.forEach(e => showNoti(e,'error'));
          response.success.forEach(e => showNoti(e, 'success'));
        })
        .catch((error) => console.log(`Ocurrio un error: ${error}`));
    });
  };

  const editData = (values, actions) => {
    values.fechaultimamodificacion = dateYMD.slice(0, 10);
    values.usuarioultimamodificacion = userInfo.id;

    values.id = rowEditData.id;
    ServiceLogica.updateData(values)
      .then((response) => {
        setActualizar(!actualizar);
        handleModalEdit();
        showNoti("Elemento guardado", "success");
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  //Detail
  const editDataDetalle = (values, actions) => {
    values.fechaultimamodificacion = dateYMD.slice(0, 10);
    values.usuarioultimamodificacion = userInfo.id;
    ServiceLogica.updateDataDetalle(values)
      .then((response) => {
        setActualizar(!actualizar);
        handleModalDetail();
        showNoti("Elemento guardado", "success");
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  const addDataDetalle = (values, actions) => {
    values.usuariocreacion = userInfo.id;
    ServiceLogica.addDataDetalle(values)
      .then((response) => {
        setActualizar(!actualizar);
        handleModalDetail();
        showNoti("Elemento creado", "success");
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  const deleteDataDetalle = () => {
    selectRowsDetalle.map((ele) => {
      const rowelementDetalle = findElement(rowsDetalle, ele);
      const rowStatus = {
        ...rowelementDetalle,
        tipoestado: 2,
        fechaultimamodificacion: dateYMD.slice(0, 10),
        usuarioultimamodificacion: userInfo.id,
      };
      return ServiceLogica.updateDataDetalle(rowStatus)
        .then((response) => {
          setActualizar(!actualizar);
          setSelectRowsDetalle([]);
          showNoti("Elemento inactivado", "success");
        })
        .catch((error) => {
          console.log(`Ocurrio un error: ${error}`);
          showNoti("Ocurrio un error al inactivar", "error");
        });
    });
  };

  const onSubmit = (values, actions) => {
    submit(values, rowEditDetalle, editDataDetalle, addDataDetalle);
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: "15px",
      }}
    >
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            p: 1,
            textAlign: "start",
            paddingLeft: "10px",
            paddingTop: "23px",
          }}
        >
          <Box
            sx={{
              p: 1,
              width: "100%",
              textAlign: "start",
            }}
          >
            <span className="title-view">Configuración lógica</span>
          </Box>

          <Box sx={{ p: 1, flexShrink: 10, textAlign: "left" }}>
            <Button
              className="add-button"
              onClick={(e) => {
                setDisabled(false);
                setDisabledDetalle(false);
                handleModalEdit("");
              }}
            >
              <AddBoxIcon />
            </Button>

            {!selectRows.length ? (
              <Button
                className="add-button"
                onClick={handleConfirmation}
                disabled
              >
                <DeleteIcon color="disabled" />
              </Button>
            ) : (
              <Button className="add-button" onClick={handleDeleteItem}>
                <DeleteIcon />
              </Button>
            )}
          </Box>
        </Box>
      </div>

      <SuccessMessage
        open={noti}
        setNoti={setNoti}
        message={messageNoti}
        setMessageNoti={setMessageNoti}
        severity={severity}
      />

      <TableData
        columns={columns}
        rows={rows}
        onRowClick={() => {/* do nothing */}}
        setSelectRows={setSelectRows}
      />

      {/* Modal logica */}
      <ComponentModal
        title={getTitle(rowEditData)}
        open={stateModalEdit}
        onClose={handleModalEdit}
        setStateModal={handleConfirmClose}
      >
        <Formik
          initialValues={
            rowEditData
              ? {
                  nombre: rowEditData.nombre,
                  descripcion: rowEditData.descripcion,
                  tipoestado: rowEditData.tipoestado,
                }
              : {
                  nombre: "",
                  descripcion: "",
                  tipoestado: 1,
                }
          }
          enableReinitialize
          validationSchema={validation}
          onSubmit={rowEditData ? editData : addData}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form className="form-detalle" id="logica">
              <FieldText
                name="nombre"
                type="text"
                labelText="Nombre:"
                placeholder="Nombre"
                value={values.nombre}
                onChange={handleChange}
                disabled={disabled}
              />
              {touched.nombre && errors.nombre && (
                <AlertError message={errors.nombre} />
              )}

              <FieldTextMultiline
                multiline
                minRows={4}
                name="descripcion"
                type="text"
                labelText="Descripción:"
                placeholder="Descripción"
                value={values.descripcion}
                onChange={handleChange}
                disabled={disabled}
              />
              {touched.descripcion && errors.descripcion && (
                <AlertError message={errors.descripcion} />
              )}

              <DialogActions>
                <Button className="save-button" type="submit">
                  {getButtonText(rowEditData)}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </ComponentModal>

      {/* Modal logica Tabla */}
      <ComponentModal
        title={titleDetailModal}
        open={modalDetalles}
        handleModal={handleModalDetails}
        setStateModal={setModalDetalles}
        class="config-Modal dialog-Modal"
      >
        <TableContainer
          component={Paper}
          style={{ maxWidth: "1000px !important" }}
        >
          <div style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                p: 1,
                textAlign: "start",
                paddingLeft: "10px",
                paddingTop: "2px",
              }}
            >
              <Box
                sx={{ p: 1, flexShrink: 10, textAlign: "right", width: "100%" }}
              >
                <Button
                  className="add-button"
                  onClick={(e) => {
                    setDisabledDetalle(false);
                    handleModalDetail("");
                  }}
                >
                  <AddBoxIcon />
                </Button>
                {!selectRowsDetalle.length ? (
                  <Button
                    className="add-button"
                    onClick={handleConfirmationDetalle}
                    disabled
                  >
                    <DeleteIcon color="disabled" />
                  </Button>
                ) : (
                  <Button
                    className="add-button"
                    onClick={handleDeleteDetalleItem}
                  >
                    <DeleteIcon />
                  </Button>
                )}
              </Box>
            </Box>
          </div>

          <TableData
            columns={columnsDetalle}
            rows={rowsDetalle}
            setSelectRows={setSelectRowsDetalle}
            onRowClick={() => {/* do nothing */}}
          />
        </TableContainer>
      </ComponentModal>

      {/* Modal de crear detalle logica */}
      <ComponentModal
        title={
          rowEditDetalle ? "Editar detalle lógico" : "Crear detalle lógico "
        }
        open={stateModalDetalle}
        onClose={handleModalDetail}
        setStateModal={handleConfirmCloseDetalle}
      >
        <Formik
          initialValues={
            rowEditDetalle
              ? {
                  id: rowEditDetalle.id,
                  nombre: rowEditDetalle.nombre,
                  idtablerologico: rowEditDetalle.idtablerologico,
                  tipoestado: rowEditDetalle.tipoestado,
                  operadorlogico: rowEditDetalle.operadorlogico,
                  idcampoorigen: rowEditDetalle.idcampoorigen,
                  idcampodestino: rowEditDetalle.idcampodestino,
                  operadorrelacional: rowEditDetalle.operadorrelacional,
                  fechacreacion: rowEditDetalle.fechacreacion,
                  usuariocreacion: rowEditDetalle.usuariocreacion,
                  fechaultimamodificacion:
                    rowEditDetalle.fechaultimamodificacion,
                  usuarioultimamodificacion:
                    rowEditDetalle.usuarioultimamodificacion,
                }
              : {
                  nombre: "",
                  idtablerologico: rowEditData.id, //tablero que se esta agregando
                  tipoestado: 1,
                  operadorlogico: "",
                  idcampoorigen: "",
                  idcampodestino: "",
                  operadorrelacional: "",
                  fechacreacion: "",
                  usuariocreacion: "",
                  fechaultimamodificacion: "",
                  usuarioultimamodificacion: "",
                }
          }
          enableReinitialize
          validationSchema={validationDetalle}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form id="logicaDetalle">
              <FieldText
                name="nombre"
                type="text"
                labelText="Nombre"
                value={values.nombre}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              {touched.nombre && errors.nombre && (
                <AlertError message={errors.nombre} />
              )}

              <SelectField
                name="idcampoorigen"
                data={optionsEE}
                optV={"id"}
                labelText={"Campo a evaluar"}
                optL={"alias"}
                value={values.idcampoorigen}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              {touched.idcampoorigen && errors.idcampoorigen && (
                <AlertError message={errors.idcampoorigen} />
              )}

              <SelectField
                name="operadorrelacional"
                data={relationalOperatorList}
                optV={"value"}
                labelText={"Operador relacional"}
                optL={"nombre"}
                value={values.operadorrelacional}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              {touched.operadorrelacional && errors.operadorrelacional && (
                <AlertError message={errors.operadorrelacional} />
              )}

              <SelectField
                name="idcampodestino"
                data={optionsEE}
                optV={"id"}
                labelText={"Valor a evaluar"}
                optL={"alias"}
                value={values.idcampodestino}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              {touched.idcampodestino && errors.idcampodestino && (
                <AlertError message={errors.idcampodestino} />
              )}
              <SelectField
                name="operadorlogico"
                data={logicOperatorList}
                optV={"value"}
                labelText={"Operador lógico"}
                optL={"nombre"}
                value={values.operadorlogico}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              {touched.operadorlogico && errors.operadorlogico && (
                <AlertError message={errors.operadorlogico} />
              )}
              <DialogActions>
                <Button className="save-button" type="submit">
                  {getButtonText(rowEditDetalle)}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </ComponentModal>
      {/* Modal detalle detalles logico -.- */}
      <ComponentModal
        title={"Detalle lógico"}
        open={modalDetalleLogico}
        onClose={handleModalLogicDetail}
        setStateModal={setModalDetalleLogico}
      >
        <Formik
          initialValues={
            rowEditDetalle
              ? {
                  id: rowEditDetalle.id,
                  nombre: rowEditDetalle.nombre,
                  idtablerologico: rowEditDetalle.idtablerologico,
                  tipoestado: rowEditDetalle.tipoestado,
                  operadorlogico: rowEditDetalle.operadorlogico,
                  idcampoorigen: rowEditDetalle.idcampoorigen,
                  idcampodestino: rowEditDetalle.idcampodestino,
                  operadorrelacional: rowEditDetalle.operadorrelacional,
                  fechacreacion: rowEditDetalle.fechacreacion,
                  usuariocreacion: rowEditDetalle.usuariocreacion,
                  fechaultimamodificacion:
                    rowEditDetalle.fechaultimamodificacion,
                  usuarioultimamodificacion:
                    rowEditDetalle.usuarioultimamodificacion,
                }
              : {
                  nombre: "",
                  idtablerologico: rowEditDetalle.id, //tablero que se esta agregando
                  tipoestado: 1,
                  operadorlogico: "",
                  idcampoorigen: "",
                  idcampodestino: "",
                  operadorrelacional: "",
                  fechacreacion: "",
                  usuariocreacion: "",
                  fechaultimamodificacion: "",
                  usuarioultimamodificacion: "",
                }
          }
          enableReinitialize
          validationSchema={validationDetalle}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form id="logicaDetalle">
              <FieldText
                name="nombre"
                type="text"
                labelText="Nombre"
                value={values.nombre}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <SelectField
                name="idcampoorigen"
                data={optionsEE}
                optV={"id"}
                labelText={"Campo a evaluar"}
                optL={"nombre"}
                value={values.idcampoorigen}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <SelectField
                name="operadorrelacional"
                data={relationalOperatorList}
                optV={"value"}
                labelText={"Operador relacional"}
                optL={"nombre"}
                value={values.operadorrelacional}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <SelectField
                name="idcampodestino"
                data={optionsEE}
                optV={"id"}
                labelText={"Valor a evaluar"}
                optL={"nombre"}
                value={values.idcampodestino}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <SelectField
                name="operadorlogico"
                data={logicOperatorList}
                optV={"value"}
                labelText={"Operador lógico"}
                optL={"nombre"}
                value={values.operadorlogico}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <RadioField
                label="Tipo de estado"
                name="tipoestado"
                data={estado}
                onChange={handleChange}
                checked={values.tipoestado}
                value={values.tipoestado}
                disabled={disabledDetalle}
              />
              <FieldText
                name="fechacreacion"
                type="text"
                labelText="Fecha de creación"
                value={values.fechacreacion}
                disabled={disabledDetalle}
              />
              <FieldText
                name="usuariocreacion"
                type="text"
                labelText="Usuario creación"
                value={values.usuariocreacion}
                disabled={disabledDetalle}
              />
              <FieldText
                name="fechamodificacion"
                type="text"
                labelText="Fecha de modificación"
                value={values.fechaultimamodificacion}
                disabled={disabledDetalle}
              />
              <FieldText
                name="usuariomodificacion"
                type="text"
                labelText="Usuario última modificación"
                value={values.usuarioultimamodificacion}
                disabled={disabledDetalle}
              />
            </Form>
          )}
        </Formik>
      </ComponentModal>
      <ModalDelete 
          open={openModalDelete}
          onClose={handleCloseModalDelete}
          onYes={deleteData}
      />
      <ModalDelete 
          open={openModalDeleteDetalle}
          onClose={handleCloseModalDeleteDetalle}
          onYes={deleteDataDetalle}
      />
    </div>
  );
};

export default ConfigLogica;
