import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@material-ui/core/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const ComponentModal = (props) => {
	//props.title = titulo
	// porps.modal = estado de la vista
	// habdleModal = funcion de abrir y cerrar
	//children = datos del modal
	// setStateModal para cerrar el modal
	return (
		<Dialog
			className={ props.class ? props.class:'dialog-Modal'}
			open={props.open}
			TransitionComponent={Transition}
			keepMounted
			scroll={'body'}
			onClose={props.handleModal}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
			fullWidth={props.isFull}
			maxWidth={props.maxWidth}
			disableEnforceFocus
			>
			<DialogTitle id='alert-dialog-slide-title'>
				<div id='title'>{props.title}</div>
				<Button className='add-button' onClick={() => {	props.setStateModal(false);if(props.handleModal){props.handleModal()}}}>
                    <CloseIcon />
                </Button>
			</DialogTitle>
			<DialogContent  >
				<DialogContentText id='alert-dialog-slide-description'>
					{props.children}
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
};

export default ComponentModal;
