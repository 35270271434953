import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FunctionsIcon from "@material-ui/icons/Functions";
//iconos
import SettingsIcon from "@material-ui/icons/Settings";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import ConfigSalida from "containers/ConfigSalida";
import React from "react";
import Tab from "../../componets/Custom/Navigation/Tabs/TabContainer/Tab/CustomizedTab";
import { a11yProps, TabPanel } from "../../componets/Dashboard/index";
import ConfigCalculo from "../../containers/ConfigCalculo/index";
import TablaEjecuciones from "../../containers/Ejecuciones/index";
import ElementosEntrada from "./views/inputElements/index";
import ConfigLogica from "./views/logicConfiguration/index";
import ModalConfirmCancel from "componets/Custom/Utils/ModalConfirmCancel";

const Administracion = () => {
	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className='containerTab containerPagos'>
			{/* <a>Link/url</a> */}
			<br></br>
			<AppBar position='relative'>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label='simple tabs example'>
					<Tab
						label='Ejecuciones'
						icon={<DoubleArrowIcon />}
						{...a11yProps(0)}
					/>
					<Tab
						label='Elementos de entrada'
						icon={<ExitToAppIcon />}
						{...a11yProps(1)}
					/>
					<Tab
						label='Configuración logica'
						icon={<SettingsIcon />}
						{...a11yProps(2)}
					/>
					<Tab
						label='Configuración cálculo'
						icon={<FunctionsIcon />}
						{...a11yProps(3)}
					/>
					<Tab
						label='Elementos de salida'
						icon={<VerticalAlignTopIcon />}
						{...a11yProps(4)}
					/>
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0}>
				<div className="containerTab">
					<TablaEjecuciones />
				</div>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<div className="containerTab">
					<ElementosEntrada />
				</div>
			</TabPanel>
			<TabPanel value={value} index={2}>
				<div className="containerTab">
					<ConfigLogica />
				</div>
			</TabPanel>
			<TabPanel value={value} index={3}>
				<div className="containerTab">
					<ConfigCalculo />
				</div>
			</TabPanel>
			<TabPanel value={value} index={4}>
				<div className="containerTab">
					<ConfigSalida />
				</div>
			</TabPanel>
			<ModalConfirmCancel />
		</div>
	);
};

export default Administracion;
