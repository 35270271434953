import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TuneIcon from "@material-ui/icons/Tune";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import SelectField from "componets/Custom/Input/Select/SelectModalForm";
import FieldText from "componets/Custom/Input/TextField/TextFieldModalForm";
import AlertError from "componets/ErrorMessage";
import RadioField from "componets/Input/RadioField";
import ComponentModal from "componets/Modal";
import SuccessMessage from "componets/SuccessMessage";
import TableData from "componets/TableData";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import ModalDelete from "../../componets/Custom/Utils/ModalDelete";
import InputElementService from "../../pages/Administracion/services/InputElementService.service";
import ServiceCalculo from "../../Services/ServiceCalculo.service";
import { MotorContext } from "context/MotorContext";

//User
const userInfoLocal = window.localStorage.getItem("userInfo");
const userInfo = JSON.parse(userInfoLocal);
const dateYMD = moment().format();

const fiendEE = (listData, value) => {
  const data = listData.find((ele) => ele.id === value);
  return data.alias || data.nombre;
};

const validLength = (value) => {
  return value > 0;
};

const deleteItem = (selectRows, handleOpenModalDelete, showNotification) => {
  if (validLength(selectRows.length)) {
    handleOpenModalDelete();
  } else {
    showNotification("Seleccione un elemento de la tabla", "error");
  }
};

const deleteItemDetalle = (selectRowsDetalle, handleOpenModalDeleteDetalle, showNotification) => {
  if (validLength(selectRowsDetalle.length)) handleOpenModalDeleteDetalle();
  else showNotification("seleccione un elemento de la tabla", "error");
};

const handleData = (data, setTitleDetailModal, setRowEditData, actualizarRowsDetalle) => {
  if (data) {
    let nombre = data.nombre;
    setTitleDetailModal(nombre);
    setRowEditData(data);
    actualizarRowsDetalle(data.id);
  } else {
    setRowEditData("");
    document.getElementById("logica").reset();
  }
};

const handleDataEdit = (data, setRowEditData, actualizarRowsDetalle) => {
  if (data) {
    setRowEditData(data);
    actualizarRowsDetalle(data.id);
  } else {
    setRowEditData("");
    document.getElementById("calculo").reset();
  }
};

const handleDataDetalle = (data, setRowEditDetalle) => {
  if (data) {
    setRowEditDetalle(data);
  } else {
    setRowEditDetalle("");
    document.getElementById("detallecalculo").reset();
  }
};

const submit = (values, rowEditDetalle, editDataDetalle, addDataDetalle) => {
  if (rowEditDetalle && rowEditDetalle !== "") {
    editDataDetalle(values, null);
  } else {
    addDataDetalle(values, null);
  }
};
// onSubmit={rowEditData ? editData : addData}
const submitData = (values, rowEditData, editData, addData) => {
  if (rowEditData) {
    editData(values, null);
  } else {
    addData(values, null);
  }
};

const getTitle = (rowToGetTitle) => {
  return rowToGetTitle ? "Editar registro" : "Crear registro";
};

const getTitleAcept = (rowToGetTitle) => {
  return rowToGetTitle !== "" ? "Aceptar" : "Crear";
};

const getTitleSave = (rowToGetTitle) => {
  return rowToGetTitle !== "" ? "Guardar" : "Crear";
};

const getTitleEditDetail = (rowToGetTitle) => {
  return rowToGetTitle !== "" ? "Detalle cálculo" : "Crear detalle cálculo";
};

const CalculationConfiguration = () => {
  const [rows, setRows] = useState([]);
  const [selectRows, setSelectRows] = useState([]);
  const [actualizar, setActualizar] = useState(false);

  //Logic Modal
  const [stateModalEdit, setStateModalEdit] = useState(false);
  const [modaldetail, setModaldetail] = useState(false);
  const [rowEditData, setRowEditData] = useState([]);

  //Modal logic detail
  const [rowsDetalle, setRowsDetalle] = useState([]);
  const [dataDetalle, setDataDetalle] = useState([]);
  const [stateModalDetalle, setStateModalDetalle] = useState(false);
  const [selectRowsDetalle, setSelectRowsDetalle] = useState([]); //selected rows
  const [modalDetalleLogica, setModalDetalleLogica] = useState(false); //para el detalle de lógica
  const [rowEditDetalle, setRowEditDetalle] = useState([]); //modified cell

  //Edit
  const [disabled, setDisabled] = useState(true);
  const [disabledDetalle, setDisabledDetalle] = useState(true);

  //Input elements Options
  const [optionsEE, setOptionsEE] = useState([]);
  const [functionOptions, SetFunctionOptions] = useState([]);
  const [stateOptions, SetStateOptions] = useState([]);
  const [matematicOptions, SetMatematicOptions] = useState([]);

  const [modalDetalles, setModalDetalles] = useState(false);
  const [titleDetailModal, setTitleDetailModal] = useState("");

  const [severity, setSeverity] = useState("success");
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [openModalDeleteDetalle, setOpenModalDeleteDetalle] =
    React.useState(false);
  //Notification
  const [notificationState, setNotificationState] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const {
    modalConfirmCancelProps, setModalConfirmCancelProps
  } = useContext(MotorContext);

  const handleConfirmClose = () => {
    setModalConfirmCancelProps({
      ...modalConfirmCancelProps,
      open: true,
      onYes: setStateModalEdit,
    });
  }

  const handleConfirmCloseDetalle = () => {
    setModalConfirmCancelProps({
      ...modalConfirmCancelProps,
      open: true,
      onYes: setStateModalDetalle,
    });
  }

  const handleOpenModalDelete = () => {
    setOpenModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleDeleteItem = () => {
    deleteItem(selectRows, handleOpenModalDelete, showNotification);
  };

  const handleOpenModalDeleteDetalle = () => {
    setOpenModalDeleteDetalle(true);
  };

  const handleCloseModalDeleteDetalle = () => {
    setOpenModalDeleteDetalle(false);
  };

  const handleDeleteItemDetalle = () => {
    deleteItemDetalle(selectRowsDetalle, handleOpenModalDeleteDetalle, showNotification);
  };

  const showNotification = (mensaje, sev) => {
    setNotificationMessage(mensaje);
    setSeverity(sev);
    setNotificationState(true);
  };

  //Modal detail table
  const handleModalDetail = (data) => {
    handleData(data, setTitleDetailModal, setRowEditData, actualizarRowsDetalle);
    setModalDetalles(!modalDetalles);
  };

  const handleModalEdit = (data) => {
    handleDataEdit(data, setRowEditData, actualizarRowsDetalle);
    setStateModalEdit(!stateModalEdit);
  };

  const handleModalDetalle = (data) => {
    handleDataDetalle(data, setRowEditDetalle);
    setStateModalDetalle(!stateModalDetalle);
  };

  const handleModalDetalleLogica = (data) => {
    handleDataDetalle(data, setRowEditDetalle);
    setModalDetalleLogica(!modalDetalleLogica);
  };
  
  const validationDetalle = Yup.object().shape({
    secuencia: Yup.number().integer("La secuencia puede ser únicamente con valores enteros.").required(
      "Por favor agregue un valor para Secuencia."
    ).min(1, "El valor mínimo para la secuencia es 1"),
    nombre: Yup.string().required("Por favor agregue un valor para Nombre."),
    idcampoentrada: Yup.string().required(
      "Por favor agregue un valor para Campo entrada."
    ),
    operadormatematico: Yup.string().required(
      "Por favor seleccione un operador matemático."
    ),
    funcion: Yup.string().required(
      "Por favor seleccione una opción."
    ),
    valor: Yup.number().when('funcion', {
      is: (funcion) =>  funcion && funcion.length > 0 && funcion  != "NA",
      then: Yup.number().required("Es necesario agregar un valor.").min(1, "El valor mínimo para el valor es 1.").integer("El valor puede ser únicamente con valores enteros."),
      otherwise: Yup.number()
    })
  });

  //form validation
  const validation = Yup.object().shape({
    nombre: Yup.string().required("Por favor agregue un valor para Nombre."),
    descripcion: Yup.string().required(
      "Por favor agregue un valor para Descripcion."
    ),
    tipoestado: Yup.string().required(
      "Por favor agregue un valor para Tipo de estado."
    ),
  });

  //Columns table
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 320,
      editable: false,
    },
    {
      field: "descripcion",
      headerName: "Descripción",
      width: 280,
      editable: false,
    },
    {
      field: "acciones",
      headerName: "Acciones",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <strong>
          <div className="column-actions">
            <Button
              className="add-button"
              onClick={() => {
                setDisabled(false);
                handleModalEdit(params.row);
              }}
            >
              <EditIcon />
            </Button>

            <Button
              style={{ minWidth: "18px" }}
              className="add-button"
              onClick={() => {
                setDisabled(true);
                setDisabledDetalle(true);
                handleModalDetail(params.row);
              }}
            >
              <TuneIcon />
            </Button>
          </div>
        </strong>
      ),
      filterable: false,
    },
  ];

  //Columns detail table
  const columnsDetalle = [
    {
      field: "secuencia",
      headerName: "Secuencia",
      width: 90,
      editable: false,
      type: "number",
    },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 280,
      editable: false,
    },
    {
      field: "operadormatematico",
      headerName: "Oper. Matemát.",
      width: 130,
      editable: false,
    },
    {
      field: "idcampoentrada",
      headerName: "Campo entrada",
      width: 280,
      editable: false,
      valueGetter: (params) => fiendEE(optionsEE, params.value),
    },
    {
      field: "funcion",
      headerName: "Función",
      width: 150,
      editable: false,
    },
    {
      field: "valor",
      headerName: "Valor",
      width: 85,
      type: "number",
      editable: false,
    },
    {
      field: "secuenciaanterior",
      headerName: "Secuencia anterior",
      width: 150,
      type: "number",
      editable: false,
    },
    {
      field: "acciones",
      headerName: "Acciones",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <strong>
          <div className="column-actions">
            <Button
              className="add-button"
              onClick={() => {
                setDisabledDetalle(false);
                handleModalDetalle(params.row);
              }}
            >
              <EditIcon />
            </Button>
          </div>
        </strong>
      ),
      filterable: false,
    },
  ];

  //data detalle
  const actualizarRowsDetalle = (id) => {
    const newData = findElementDetalle(dataDetalle, id);
    setRowsDetalle(newData);
  };
  //Agregar datos
  const addData = (values, actions) => {
    values.usuariocreacion = userInfo.id;

    ServiceCalculo.addData(values, null)
      .then((response) => {
        handleResponse();
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };
  const handleResponse = () => {
    setActualizar(!actualizar);
    handleModalEdit();
    showNotification("Elemento guardado", "success");
  };
  //Buscar elemento en la lista de datos
  const findElement = (listData, id) => {
    return listData.find((ele) => ele.id === id);
  };

  const findElementDetalle = (listData, id) => {
    return listData.filter((ele) => ele.idtablerocalculo === id);
  };

  //Eliminar datos
  const deleteData = () => {
    selectRows.map((ele) => {
      const rowelement = findElement(rows, ele);

      return ServiceCalculo.deleteData(rowelement.id)
        .then((response) => {
          setActualizar(!actualizar);
          setSelectRows([]);
          response.error.forEach((e) => showNotification(e, "error"));
          response.success.forEach((e) => showNotification(e, "success"));
        })
        .catch((error) => console.log(`Ocurrio un error: ${error}`));
    });
  };

  const editData = (values, actions) => {
    values.fechaultimamodificacion = dateYMD.slice(0, 10);
    values.usuarioultimamodificacion = userInfo.id;

    values.id = rowEditData.id;
    ServiceCalculo.updateData(values)
      .then((response) => {
        handleResponse();
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  //detalle
  const editDataDetalle = (values, actions) => {
    values.fechaultimamodificacion = dateYMD.slice(0, 10);
    values.usuarioultimamodificacion = userInfo.id;
    ServiceCalculo.updateDataDetalle(values)
      .then((response) => {
        setActualizar(!actualizar);
        handleModalDetalle();
        showNotification("Elemento guardado", "success");
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  const addDataDetalle = (values, actions) => {
    values.usuariocreacion = userInfo.id;
    ServiceCalculo.addDataDetalle(values)
      .then((response) => {
        setActualizar(!actualizar);
        handleModalDetalle();
        showNotification("Elemento creado", "success");
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  const deleteDataDetalle = () => {
    selectRowsDetalle.map((ele) => {
      const rowelementDetalle = findElement(rowsDetalle, ele);

      const rowStatus = {
        ...rowelementDetalle,
        tipoestado: 2,
        fechaultimamodificacion: dateYMD.slice(0, 10),
        fechacreacion: rowelementDetalle.fechacreacion.slice(0, 10),
        usuarioultimamodificacion: userInfo.id,
      };
      return ServiceCalculo.updateDataDetalle(rowStatus)
        .then((response) => {
          setActualizar(!actualizar);
          setSelectRowsDetalle([]);
          showNotification("Elemento inactivado", "success");
        })
        .catch((error) => console.log(`Ocurrio un error: ${error}`));
    });
  };

  const onSubmit = (values, actions) => {
    submit(values, rowEditDetalle, editDataDetalle, addDataDetalle);
  };

  const onSubmitData = (values, actions) => {
    submitData(values, rowEditData, editData, addData);
  };

  //Actualizacion de datos de la tabla
  useEffect(() => {
    ServiceCalculo.getData()
      .then((response) => {
        setRows(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));

    ServiceCalculo.getDataDetalle()
      .then((response) => {
        setDataDetalle(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
    // llenar campo entrada
    InputElementService.getElementosEntrada()
      .then((response) => {
        setOptionsEE(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
    //Llenar funciones
    let params = {
      nombretabla: "tablerocalculodetalle",
      nombrecampo: "Funcion",
    };
    InputElementService.getEntidadValorMultiple(params)
      .then((response) => {
        SetFunctionOptions(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
    //llenar operador matematico
    params = {
      nombretabla: "tablerocalculodetalle",
      nombrecampo: "OperadorMatematico",
    };
    InputElementService.getEntidadValorMultiple(params)
      .then((response) => {
        SetMatematicOptions(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
    //llenar estado
    params = {
      nombretabla: "tablerocalculodetalle",
      nombrecampo: "TipoEstado",
    };
    InputElementService.getEntidadValorMultiple(params)
      .then((response) => {
        SetStateOptions(response);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  }, [actualizar]);

  useEffect(() => {
    actualizarRowsDetalle(rowEditData.id);
  }, [dataDetalle]);

  return (
    <div className="config-calculo">
      <div className="container-title">
        <p className="title"> Configuración cálculo </p>
        <SuccessMessage
          open={notificationState}
          setNoti={setNotificationState}
          message={notificationMessage}
          setMessageNoti={setNotificationMessage}
          severity={severity}
        />

        <ModalDelete
          open={openModalDelete}
          onClose={handleCloseModalDelete}
          onYes={deleteData}
        />

        <ModalDelete
          open={openModalDeleteDetalle}
          onClose={handleCloseModalDeleteDetalle}
          onYes={deleteDataDetalle}
        />

        <div className="actions">
          <Button
            className="add-button"
            onClick={(e) => {
              setDisabled(false);
              setDisabledDetalle(false);
              handleModalEdit("");
            }}
          >
            <AddBoxIcon />
          </Button>
          {!selectRows.length ? (
            <Button className="add-button" onClick={handleDeleteItem} disabled>
              <DeleteIcon color="disabled" />
            </Button>
          ) : (
            <Button className="add-button" onClick={handleDeleteItem}>
              <DeleteIcon />
            </Button>
          )}
        </div>
      </div>
      <TableData
        columns={columns}
        rows={rows}
        setSelectRows={setSelectRows}
        onRowClick={() => {
          /* do nothing */
        }}
      />

      {/* Modal calculo */}
      <ComponentModal
        title={getTitle(rowEditData)}
        open={stateModalEdit}
        onClose={handleModalEdit}
        setStateModal={handleConfirmClose}
      >
        <Formik
          initialValues={
            rowEditData
              ? {
                nombre: rowEditData.nombre,
                descripcion: rowEditData.descripcion,
                tipoestado: rowEditData.tipoestado,
              }
              : {
                nombre: "",
                descripcion: "",
                tipoestado: 1,
              }
          }
          enableReinitialize
          validationSchema={validation}
          onSubmit={onSubmitData}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form className="form-detalle" id="calculo">
              <FieldText
                name="nombre"
                type="text"
                labelText="Nombre"
                value={values.nombre}
                onChange={handleChange}
                disabled={disabled}
              />
              {touched.nombre && errors.nombre && (
                <AlertError message={errors.nombre} />
              )}

              <FieldText
                name="descripcion"
                type="text"
                labelText="Descripción"
                value={values.descripcion}
                onChange={handleChange}
                disabled={disabled}
              />
              {touched.descripcion && errors.descripcion && (
                <AlertError message={errors.descripcion} />
              )}

              <DialogActions>
                <Button className="save-button" type="submit">
                  {getTitleAcept(rowEditData)}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </ComponentModal>
      {/* Modal logica Tabla */}
      <ComponentModal
        title={titleDetailModal}
        open={modalDetalles}
        onClose={handleModalDetail}
        setStateModal={setModalDetalles}
        class="config-Modal dialog-Modal"
      >
        <TableContainer
          component={Paper}
          style={{ maxWidth: "1000px !important" }}
        >
          <div style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                p: 1,
                textAlign: "start",
                paddingLeft: "10px",
                paddingTop: "2px",
              }}
            >
              <Box
                sx={{ p: 1, flexShrink: 10, textAlign: "right", width: "100%" }}
              >
                <Button
                  className="add-button"
                  onClick={(e) => {
                    setDisabledDetalle(false);
                    handleModalDetalle("");
                  }}
                >
                  <AddBoxIcon />
                </Button>
                {!selectRowsDetalle.length ? (
                  <Button
                    className="add-button"
                    onClick={handleDeleteItemDetalle}
                    disabled
                  >
                    <DeleteIcon color="disabled" />
                  </Button>
                ) : (
                  <Button
                    className="add-button"
                    onClick={handleDeleteItemDetalle}
                  >
                    <DeleteIcon />
                  </Button>
                )}
              </Box>
            </Box>
          </div>

          <TableData
            columns={columnsDetalle}
            rows={rowsDetalle}
            setSelectRows={setSelectRowsDetalle}
            onRowClick={() => {
              /* do nothing */
            }}
          />
        </TableContainer>
      </ComponentModal>

      {/* Modal Detalles */}
      <ComponentModal
        title={"Detalles del Registro"}
        open={modaldetail}
        onClose={handleModalEdit}
        setStateModal={setModaldetail}
      >
        <Formik
          initialValues={
            rowEditData
              ? {
                nombre: rowEditData.nombre,
                descripcion: rowEditData.descripcion,
                tipoestado: rowEditData.tipoestado,
                fechacreacion: rowEditData.fechacreacion,
                usuariocreacion: rowEditData.usuariocreacion,
                fechaultimamodificacion: rowEditData.fechaultimamodificacion,
                usuarioultimamodificacion:
                  rowEditData.usuarioultimamodificacion,
              }
              : {
                nombre: "",
                descripcion: "",
                tipoestado: 1,
                fechacreacion: "",
                usuariocreacion: "",
                fechamodificacion: "",
                Usuariomodificacion: "",
              }
          }
          enableReinitialize
          validationSchema={validation}
          onSubmit={onSubmitData}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form className="form-detalle" id="calculo">
              <FieldText
                name="nombre"
                type="text"
                labelText="Nombre"
                value={values.nombre}
                onChange={handleChange}
                disabled={disabled}
              />
              <FieldText
                name="descripcion"
                type="text"
                labelText="Descripción"
                value={values.descripcion}
                onChange={handleChange}
                disabled={disabled}
              />
              <RadioField
                label="Tipo de estado"
                name="tipoestado"
                data={stateOptions}
                onChange={handleChange}
                checked={values.tipoestado}
                value={values.tipoestado}
                disabled={disabled}
              />
              <FieldText
                name="fechacreacion"
                type="text"
                labelText="Fecha de creación"
                value={values.fechacreacion}
                disabled={disabled}
              />
              <FieldText
                name="usuariocreacion"
                type="text"
                labelText="Usuario creación"
                value={values.usuariocreacion}
                disabled={disabled}
              />
              <FieldText
                name="fechamodificacion"
                type="text"
                labelText="Fecha de modificación"
                value={values.fechaultimamodificacion}
                disabled={disabled}
              />
              <FieldText
                name="usuariomodificacion"
                type="text"
                labelText="Usuario última modificación"
                value={userInfo.correo}
                disabled={disabled}
              />
            </Form>
          )}
        </Formik>
      </ComponentModal>
      {/* Modal detalle logica */}
      <ComponentModal
        title={getTitleEditDetail(rowEditDetalle)}
        open={stateModalDetalle}
        onClose={handleModalDetalle}
        setStateModal={handleConfirmCloseDetalle}
      >
        <Formik
          initialValues={
            rowEditDetalle
              ? {
                id: rowEditDetalle.id,
                idtablerocalculo: rowEditDetalle.idtablerocalculo,
                idcampoentrada: rowEditDetalle.idcampoentrada,
                nombre: rowEditDetalle.nombre,
                secuencia: rowEditDetalle.secuencia,
                funcion: rowEditDetalle.funcion,
                secuenciaanterior: rowEditDetalle.secuencia,
                operadormatematico: rowEditDetalle.operadormatematico,
                tipoestado: rowEditDetalle.tipoestado,
                valor: rowEditDetalle.valor,
              }
              : {
                idtablerocalculo: rowEditData.id,
                idcampoentrada: "",
                nombre: "",
                secuencia: "",
                funcion: "",
                secuenciaanterior: "",
                operadormatematico: "",
                valor: "",
                tipoestado: 1,
              }
          }
          enableReinitialize
          validationSchema={validationDetalle}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form id="detallecalculo">
              <FieldText
                name="secuencia"
                type="number"
                labelText="Secuencia"
                value={values.secuencia}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              {touched.secuencia && errors.secuencia && (
                <AlertError message={errors.secuencia} />
              )}
              <FieldText
                name="nombre"
                type="text"
                labelText="Nombre"
                value={values.nombre}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              {touched.nombre && errors.nombre && (
                <AlertError message={errors.nombre} />
              )}
              <SelectField
                labelText={"Operador mátematico"}
                name="operadormatematico"
                data={matematicOptions}
                optV={"nombrevalor"}
                optL={"nombrevalor"}
                value={values.operadormatematico}
                onChange={handleChange}
                disabled={disabledDetalle}
              />

              {touched.operadormatematico && errors.operadormatematico && (
                <AlertError message={errors.operadormatematico} />
              )}
              <SelectField
                name="idcampoentrada"
                data={optionsEE}
                optV={"id"}
                labelText={"Campo entrada"}
                optL={"alias"}
                value={values.idcampoentrada}
                onChange={handleChange}
                disabled={disabledDetalle}
                isOptional={false}
              />
              {touched.idcampoentrada && errors.idcampoentrada && (
                <AlertError message={errors.idcampoentrada} />
              )}
              <SelectField
                labelText={"Función"}
                name="funcion"
                data={functionOptions}
                optV={"nombrevalor"}
                optL={"nombrevalor"}
                value={values.funcion}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              {touched.funcion && errors.funcion && (
                <AlertError message={errors.funcion} />
              )}
              <FieldText
                name="valor"
                type="number"
                labelText="Valor"
                value={values.valor}
                onChange={handleChange}
              />
              {touched.valor && errors.valor && (
                <AlertError message={errors.valor} />
              )}

              <DialogActions>
                <Button className="save-button" type="submit">
                  {getTitleSave(rowEditDetalle)}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </ComponentModal>
      {/* Modal detalle de cálculo */}
      <ComponentModal
        title={"Detalle de Cálculo"}
        open={modalDetalleLogica}
        onClose={handleModalDetalleLogica}
        setStateModal={setModalDetalleLogica}
      >
        <Formik
          initialValues={
            rowEditDetalle
              ? {
                id: rowEditDetalle.id,
                idtablerocalculo: rowEditDetalle.idtablerocalculo,
                idcampoentrada: rowEditDetalle.idcampoentrada,
                nombre: rowEditDetalle.nombre,
                secuencia: rowEditDetalle.secuencia,
                funcion: rowEditDetalle.idfuncion,
                secuenciaanterior: rowEditDetalle.secuencia,
                operadormatematico: rowEditDetalle.idoperadormatematico,
                tipoestado: rowEditDetalle.tipoestado,
                fechacreacion: rowEditDetalle.fechacreacion,
                usuariocreacion: rowEditData.usuariocreacion,
                fechaultimamodificacion:
                  rowEditDetalle.fechaultimamodificacion,
                usuarioultimamodificacion:
                  rowEditDetalle.usuarioultimamodificacion,
              }
              : {
                idtablerocalculo: rowEditData.id,
                idcampoentrada: "",
                nombre: "",
                secuencia: "",
                funcion: "",
                secuenciaanterior: "",
                operadormatematico: "",
                tipoestado: 1,
              }
          }
          enableReinitialize
          validationSchema={validationDetalle}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form id="detallecalculo">
              <FieldText
                name="secuencia"
                type="number"
                labelText="Secuencia"
                value={values.secuencia}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <FieldText
                name="nombre"
                type="text"
                labelText="Nombre"
                value={values.nombre}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <SelectField
                labelText={"Operador mátematico"}
                name="operadormatematico"
                data={matematicOptions}
                optV={"nombrevalor"}
                optL={"nombrevalor"}
                value={values.operadormatematico}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <SelectField
                name="idcampoentrada"
                data={optionsEE}
                optV={"id"}
                labelText={"Campo entrada"}
                optL={"nombre"}
                value={values.idcampoentrada}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <SelectField
                labelText={"Función"}
                name="funcion"
                data={functionOptions}
                optV={"nombrevalor"}
                optL={"nombrevalor"}
                value={values.funcion}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <FieldText
                labelText={"Secuencia Anterior"}
                name="secuenciaanterior"
                optV={"value"}
                optL={"nombre"}
                value={values.secuenciaanterior}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <RadioField
                label="Tipo de estado"
                name="tipoestado"
                data={stateOptions}
                onChange={handleChange}
                checked={values.tipoestado}
                value={values.tipoestado}
                disabled={disabledDetalle}
              />
              <FieldText
                labelText={"Fecha de creación"}
                name="fechacreacion"
                optV={"value"}
                optL={"nombre"}
                value={values.fechacreacion}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <FieldText
                labelText={"Usuario creación"}
                name="usuariocreacion"
                optV={"value"}
                optL={"nombre"}
                value={values.usuariocreacion}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <FieldText
                labelText={"Fecha de modificación"}
                name="fechamodificacion"
                optV={"value"}
                optL={"nombre"}
                value={values.fechaultimamodificacion}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
              <FieldText
                labelText={"Usuario última modificación"}
                name="usuarioultimamodificacion"
                optV={"value"}
                optL={"nombre"}
                value={values.usuarioultimamodificacion}
                onChange={handleChange}
                disabled={disabledDetalle}
              />
            </Form>
          )}
        </Formik>
      </ComponentModal>
    </div>
  );
};

export default CalculationConfiguration;
