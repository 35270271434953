// Material components
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
//Icons
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@mui/material/Box";
// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import CheckLabelForm from "componets/Custom/Input/Checkbox/checkLabelForm";
import SelectField from "componets/Custom/Input/Select/SelectModalForm";
import FieldText from "componets/Custom/Input/TextField/TextFieldModalForm";
import ModalConfirm from "componets/Custom/Utils/ModalDelete";
// selects array
import {
  logicOperatorList,
  relationalOperatorList
} from "componets/DataSelect/DataArray";
import AlertError from "componets/ErrorMessage";
import ComponentModal from "componets/Modal";
// Components
import TableData from "componets/TableData";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdModeEdit } from "react-icons/md";
import * as Yup from "yup";
// Api
import ServiceReferencia from "../../../../Services/ServiceReferencia.service";
import InputElementService from "../../services/InputElementService.service";
import { MotorContext } from "context/MotorContext";

const handleEntidadesReferenciaTratadas = (entidadesReferenciaTratadas, entidades, camp, setEntidadesTratadas, setRows, setCampos) => {
  if (entidadesReferenciaTratadas.length > 0) {
    const entidadesTratadasNew = entidades
      .map((e) => {
        let camposNew = camp.filter((c) => c.identidad == e.id) || [];
        return { ...e, camposNew };
      })
    
    setEntidadesTratadas(entidadesTratadasNew);

    const row = entidadesReferenciaTratadas.reduce((p, c) => {
      if (!c.camposreferencia) return p;

      return [
        ...p,
        {
          ...c,
          campoReferencia: c.camposreferencia.descripcion,
          valorReferencia:
            c.campoentradafiltrados === null
              ? null
              : c.campoentradafiltrados.alias,
        },
      ];
    }, []);
    setRows(row);
  } else {
    setRows([]);
    setCampos(camp);
  }
};

const handleValRegistroEjecucion = (valRegistroEjecucion, setValRegistroEjecucion) => {
  if (valRegistroEjecucion) {
    setValRegistroEjecucion(false);
  }
};

const getNombre = (e) => {
  return e.alias === "" ? e.id : e.alias;
};

const handleRowReferencia = (rowReferencia, handleObtenerDatos) => {
  if (rowReferencia) {
    handleObtenerDatos();
  }
};

const getIdCampoEntradaFiltrado = (idcampoentradafiltrado, x1) => {
  return idcampoentradafiltrado === undefined ||
        idcampoentradafiltrado === ""
          ? null
          : x1.idcampoentradafiltrado;
};

const getOperadorRelacional = (OperadorRelacional) => {
  return OperadorRelacional === undefined ? "" : OperadorRelacional;
};

const handleUserInfo = () => {
  const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    if (!userInfo) {
      window.location.href = "/login";
    }
}

const ModalEntradaReferencia = (props) => {
  const { rowReferencia } = props;

  const [rows, setRows] = useState([]);
  const [selectRows, setSelectRows] = useState([]); //rows seleccioandas

  const [entrada, setEntrada] = useState([]);
  const [entidadesTratadas, setEntidadesTratadas] = useState([]);
  const [campoSecuencia, setCampoSecuencia] = useState(1);
  const [valRegistroEjecucion, setValRegistroEjecucion] = useState(false);
  // Select List
  const [campos, setCampos] = useState([]);
  //-------------------Confirmacion-------------------
  const [modalConfirmacion, setModalConfirmacion] = useState(false);
  const handleConfirmation = () => {
    setModalConfirmacion(!modalConfirmacion);
  };
  //Modal creacion
  const [stateModal, setStateModal] = useState(false);
  const [initialValuesCreateModal, setInitialValuesCreateModal] = useState({
    IdEntidadcamporeferencia: "",
    descripcion: "",
    idcampoentradafiltrado: "",
    tipoestado: 1,
    operadorlogico: "",
    secuencia: 1,
    operadorrelacional: "",
  });

  //Modal modificacion
  const [stateModalEdit, setStateModalEdit] = useState(false);
  const [rowEditData, setRowEditData] = useState([]); //celda modificada

  const {
    modalConfirmCancelProps, setModalConfirmCancelProps
  } = useContext(MotorContext);

  const handleConfirmClose = () => {
    setModalConfirmCancelProps({
      ...modalConfirmCancelProps,
        open: true,
        onYes: setStateModal,
    });
  }

  const handleConfirmCloseEdit = () => {
    setModalConfirmCancelProps({
      ...modalConfirmCancelProps,
        open: true,
        onYes: setStateModalEdit,
    });
  }

  const handleModal = () => {
    setCampoSecuencia(1);
    handleValRegistroEjecucion(valRegistroEjecucion, setValRegistroEjecucion);
    
    setInitialValuesCreateModal({
      IdEntidadcamporeferencia: "",
      descripcion: "",
      idcampoentradafiltrado: "",
      tipoestado: 1,
      operadorlogico: "",
      secuencia: 1,
      operadorrelacional: "",
    });

    setStateModal((prevState) => !prevState);
  };

  const handleModalClose = () => {
    setCampoSecuencia(1);
    handleValRegistroEjecucion(valRegistroEjecucion, setValRegistroEjecucion);
    setInitialValuesCreateModal([]);
  };

  const handleModalEdit = () => {
    setStateModalEdit(!stateModalEdit);
  };

  //Validacion del formulario
  const validation = Yup.object().shape({
    secuencia: Yup.string().required(
      "Por favor agregue un valor para secuencia."
    ),
    descripcion: Yup.string().required(
      "Por favor agregue un valor para descripción."
    ),
    operadorlogico: Yup.string().required(
      "Por favor agregue un valor para operador lógico."
    ),
    IdEntidadcamporeferencia: Yup.string().required(
      "Por favor agregue un valor para campo referencia."
    ),
  });

  const acciones = (cell) => {
    return (
      <div>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip-3">Editar campo</Tooltip>}
        >
          <Button className="add-button">
            <MdModeEdit
              onClick={(e) => {
                e.stopPropagation();
                setCampoSecuencia(cell.row.secuencia);
                setValRegistroEjecucion(cell.row.valorIdregistroenejecucion);
                setRowEditData(cell.row);
                setStateModalEdit(!stateModalEdit);
              }}
              style={{ fontSize: "1.5vw", cursor: "pointer", color: "#484848" }}
            />
          </Button>
        </OverlayTrigger>
      </div>
    );
  };

  //Columnas de la tabla
  const columns = [
    {
      field: "secuencia",
      headerName: "Sec",
      width: 60,
    },
    {
      field: "descripcion",
      headerName: "Descripción",
      width: 350,
      editable: false,
    },
    {
      field: "campoReferencia",
      headerName: "Campo Referencia",
      width: 350,
      editable: false,
    },
    {
      field: "operadorrelacional",
      headerName: "Operador",
      width: 110,
      editable: false,
    },
    {
      field: "valorReferencia",
      headerName: "Valor referencia",
      width: 250,
      editable: false,
    },
    {
      field: "",
      headerName: "Acciones",
      width: 150,
      editable: false,
      renderCell: acciones,
      filterable: false,
    },
  ];

  //Actualizacion de datos de la tabla
  useEffect(() => {
    const requests = [
      InputElementService.getEntidadCampoReferencia(props.rowReferencia.tabla),
      InputElementService.getEntidadCampoFiltrado(props.rowReferencia.tabla),
      InputElementService.getElementosEntrada(),
    ];
    Promise.all(requests)
      .then(([camposReferencia, camposFiltrado, elementosEntrada]) => {
        const EntradaNomAlias = elementosEntrada.map((e) => {
          return {
            idcampoentradafiltrado: e.id,
            nombre: getNombre(e), // Checar si si es id o que ?
          };
        });
        setCampos(camposReferencia);
        setEntrada(EntradaNomAlias);
      })
      .catch(console.log);
  }, [entidadesTratadas]);

  useEffect(() => {
    handleRowReferencia(props.rowReferencia, handleObtenerDatos);
  }, [props.rowReferencia.id]);

  const handleChangeCheckValRegEjecucion = (event) => {
    const valRegEjec = event.target.checked;
    setValRegistroEjecucion(valRegEjec);
  };

  const handleObtenerDatos = () => {
    const requests = [
      InputElementService.getEntidad(),
      InputElementService.getEntidadCampoReferencia(props.rowReferencia.tabla),
      InputElementService.getEntidadCampoFiltrado(props.rowReferencia.tabla),
      ServiceReferencia.getData(),
      InputElementService.getElementosEntrada(),
    ];
    Promise.all(requests)
      .then(
        ([
          entidades,
          camp,
          camposFiltrado,
          entidadesReferencia,
          elementosEntrada,
        ]) => {
          setRows([]);
          setCampos(camp);
          const entidadesReferenciaTratadas = entidadesReferencia.filter(
            (e) => {
              return e.idcampoentrada == props.rowReferencia.id;
            }
          );
          handleEntidadesReferenciaTratadas(entidadesReferenciaTratadas, entidades, camp, setEntidadesTratadas, setRows, setCampos);
        }
      )
      .catch(console.log);
  };
  const findEntrada = (id) => {
    return entrada.find((e) => {
      return e.idcampoentradafiltrado === parseInt(id);
    });
  };
  //Agregar datos
  const addData = (values, actions) => {
    const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    const camposecuenciaInt = parseInt(campoSecuencia);
    if (!userInfo) {
      window.location.href = "/login";
    }

    const x1 = findEntrada(values.idcampoentradafiltrado);

    const params = {
      IdEntidadcamporeferencia: parseInt(values.IdEntidadcamporeferencia),
      idcampoentrada: props.rowReferencia.id,
      idcampoentradafiltrado: getIdCampoEntradaFiltrado(values.idcampoentradafiltrado, x1),
      descripcion: values.descripcion,
      secuencia: camposecuenciaInt,
      operadorrelacional: getOperadorRelacional(values.OperadorRelacional),
      operadorlogico: values.operadorlogico,
      valorIdregistroenejecucion: valRegistroEjecucion,
      tipoestado: "1",
      usuariocreacion: userInfo.id,
    };

    ServiceReferencia.addData(params)
      .then((response) => {
        handleObtenerDatos();
        handleModal();
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  //Eliminar datos
  const deleteData = () => {
    ServiceReferencia.deleteData(selectRows)
      .then((response) => {
        handleObtenerDatos();
        setSelectRows([]);
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  // Editar datos
  const editData = (values, actions) => {
    const dateYMD = moment().format();
    const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    handleUserInfo();

    debugger;
    const x = campos.find((e) => {
      return e.id === parseInt(values.IdEntidadcamporeferencia);
    });
    const x1 = findEntrada(values.idcampoentradafiltrado);

    values.fechaultimamodificacion = dateYMD.slice(0, 10);
    values.IdEntidadcamporeferencia = x.id;
    values.idcampoentrada = props.rowReferencia.id;
    values.idcampoentradafiltrado = x1 ? x1.idcampoentradafiltrado : null;
    values.id = rowEditData.id;
    values.usuarioultimamodificacion = userInfo.id;
    values.valorIdregistroenejecucion = valRegistroEjecucion;
    ServiceReferencia.updateData(values)
      .then((response) => {
        handleObtenerDatos();
        handleModalEdit();
      })
      .catch((error) => console.log(`Ocurrio un error: ${error}`));
  };

  return (
    <div id="referencia-modal">
      <ComponentModal
        open={props.showModalReferencia}
        onClose={props.handleModalReferencia}
        setStateModal={props.setShowModalReferencia}
        isFull={true}
      >
        <div className="config-logica">
          <div className="header-cc">
            <div className="header-left-cc">
              <span className="title-view">{rowReferencia.alias}</span>
            </div>
            <div className="header-right-cc">
              <Button className="add-button" onClick={handleModal}>
                <AddBoxIcon />
              </Button>
              {!selectRows.length ? (
                <Button
                  className="add-button"
                  onClick={handleConfirmation}
                  disabled
                >
                  <DeleteIcon color="disabled" />
                </Button>
              ) : (
                <Button className="add-button" onClick={handleConfirmation}>
                  <DeleteIcon />
                </Button>
              )}
            </div>
          </div>

          <TableData
            columns={columns}
            rows={rows}
            setSelectRows={setSelectRows}
            onRowClick={() => {
              /* do nothing */
            }}
          />

          {/* Modal crear elemento */}
          <ComponentModal
            title={"Agregar registro"}
            open={stateModal}
            onClose={handleModal}
            handleModal={handleModalClose}
            setStateModal={handleConfirmClose}
          >
            <Formik
              initialValues={{
                IdEntidadcamporeferencia:
                  initialValuesCreateModal.IdEntidadcamporeferencia,
                descripcion: initialValuesCreateModal.descripcion,
                idcampoentradafiltrado:
                  initialValuesCreateModal.idcampoentradafiltrado,
                tipoestado: initialValuesCreateModal.tipoestado,
                operadorlogico: initialValuesCreateModal.operadorlogico,
                operadorrelacional: initialValuesCreateModal.operadorrelacional,
                secuencia: initialValuesCreateModal.secuencia,
              }}
              resetForm
              enableReinitialize
              validationSchema={validation}
              onSubmit={addData}
            >
              {({ errors, touched, values, handleChange }) => (
                <Form id="createLogica">
                  <FieldText
                    name="secuencia"
                    type="number"
                    inputProps={{ min: 1, maxLength: 400 }}
                    labelText="Campo Secuencia"
                    defaultValue={values.secuencia}
                    value={values.secuencia}
                    onChange={handleChange}
                  />
                  {touched.secuencia && errors.secuencia && (
                    <AlertError message={errors.secuencia} />
                  )}

                  <FieldText
                    name="descripcion"
                    type="text"
                    inputProps={{ maxLength: 400 }}
                    labelText="Descripción"
                    value={values.descripcion}
                    onChange={handleChange}
                  />
                  {touched.descripcion && errors.descripcion && (
                    <AlertError message={errors.descripcion} />
                  )}
                  <SelectField
                    name="IdEntidadcamporeferencia"
                    data={campos}
                    optV={"id"}
                    labelText={"Campo referencia"}
                    value={values.IdEntidadcamporeferencia}
                    onChange={handleChange}
                    optL={"descripcion"}
                    isOptional={false}
                  />
                  {touched.IdEntidadcamporeferencia &&
                    errors.IdEntidadcamporeferencia && (
                      <AlertError message={errors.IdEntidadcamporeferencia} />
                    )}

                  <SelectField
                    name="OperadorRelacional"
                    data={relationalOperatorList}
                    optV={"value"}
                    labelText={"Operador relacional"}
                    optL={"nombre"}
                    onChange={handleChange}
                    value={values.operadorRelacional}
                  />

                  <SelectField
                    labelText={"Valor de Filtrado"}
                    name="idcampoentradafiltrado"
                    data={entrada}
                    optV={"idcampoentradafiltrado"}
                    optL={"nombre"}
                    value={values.idcampoentradafiltrado}
                    onChange={handleChange}
                  />

                  <SelectField
                    labelText={"Operador lógico"}
                    name="operadorlogico"
                    data={logicOperatorList}
                    optV={"value"}
                    optL={"nombre"}
                    value={values.operadorlogico}
                    onChange={handleChange}
                    isOptional={false}
                  />
                  {touched.operadorlogico && errors.operadorlogico && (
                    <AlertError message={errors.operadorlogico} />
                  )}

                  <CheckLabelForm
                    checked={valRegistroEjecucion}
                    value={valRegistroEjecucion}
                    name="checkedA"
                    color="primary"
                    onChange={handleChangeCheckValRegEjecucion}
                    label="Valor Registro de Ejecución"
                  />

                  <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                    <Box>
                      <Button className="save-button" type="submit">
                        Crear
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </ComponentModal>

          {/* Modal editar elemento */}
          <ComponentModal
            title={"Editar registro"}
            open={stateModalEdit}
            //handleModal={handleModalEdit}
            setStateModal={handleConfirmCloseEdit}
            onClose={handleModalEdit}
          >
            <Formik
              initialValues={{
                IdEntidadcamporeferencia: rowEditData.IdEntidadcamporeferencia,
                descripcion: rowEditData.descripcion,
                idcampoentradafiltrado: rowEditData.idcampoentradafiltrado,
                tipoestado: rowEditData.tipoestado,
                operadorlogico: rowEditData.operadorlogico,
                operadorrelacional: rowEditData.operadorrelacional,
                secuencia: campoSecuencia,
              }}
              resetForm
              enableReinitialize
              validationSchema={validation}
              onSubmit={editData}
            >
              {({ errors, touched, values, initialValues, handleChange }) => (
                <Form>
                  <FieldText
                    name="secuencia"
                    type="number"
                    inputProps={{ min: 1, maxLength: 400 }}
                    labelText="Campo Secuencia"
                    defaultValue={values.secuencia}
                    value={values.secuencia}
                    onChange={handleChange}
                  />
                  {touched.secuencia && errors.secuencia && (
                    <AlertError message={errors.secuencia} />
                  )}

                  <FieldText
                    name="descripcion"
                    type="text"
                    inputProps={{ maxLength: 400 }}
                    labelText="Descripción"
                    value={values.descripcion}
                    onChange={handleChange}
                  />
                  {touched.descripcion && errors.descripcion && (
                    <AlertError message={errors.descripcion} />
                  )}
                  <SelectField
                    name="IdEntidadcamporeferencia"
                    data={campos}
                    optV={"id"}
                    labelText={"Campo referencia"}
                    value={values.IdEntidadcamporeferencia}
                    onChange={handleChange}
                    optL={"descripcion"}
                    isOptional={false}
                  />
                  {touched.IdEntidadcamporeferencia &&
                    errors.IdEntidadcamporeferencia && (
                      <AlertError message={errors.IdEntidadcamporeferencia} />
                    )}

                  <SelectField
                    name="operadorrelacional"
                    data={relationalOperatorList}
                    optV={"value"}
                    labelText={"Operador relacional"}
                    optL={"nombre"}
                    onChange={handleChange}
                    value={values.operadorrelacional}
                  />

                  <SelectField
                    labelText={"Valor de Filtrado"}
                    name="idcampoentradafiltrado"
                    data={entrada}
                    optV={"idcampoentradafiltrado"}
                    optL={"nombre"}
                    value={values.idcampoentradafiltrado}
                    onChange={handleChange}
                  />

                  <SelectField
                    labelText={"Operador lógico"}
                    name="operadorlogico"
                    data={logicOperatorList}
                    optV={"value"}
                    optL={"nombre"}
                    value={values.operadorlogico}
                    onChange={handleChange}
                    isOptional={false}
                  />
                  {touched.operadorlogico && errors.operadorlogico && (
                    <AlertError message={errors.operadorlogico} />
                  )}

                  <CheckLabelForm
                    checked={valRegistroEjecucion}
                    value={valRegistroEjecucion}
                    name="checkedA"
                    color="primary"
                    onChange={handleChangeCheckValRegEjecucion}
                    label="Valor Registro de Ejecución"
                  />

                  <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                    <Box>
                      <Button className="save-button" type="submit">
                        Guardar
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </ComponentModal>
        </div>

        {/* Confirmacion  */}

        <ModalConfirm
          title="¿Estás seguro de borrar este elemento?"
          open={modalConfirmacion}
          onClose={handleConfirmation}
          onYes={deleteData}
        />
      </ComponentModal>
    </div>
  );
};

export default ModalEntradaReferencia;
