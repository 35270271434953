import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import close from './close.svg'
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const Modal = (props) => {
	return (
		<Dialog
			className={ props.className || 'Modal-styles ' }
			open={props.open}m
			TransitionComponent={Transition}
			keepMounted
			scroll={'body'}
			onClose={props.handleModal}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'>
			<DialogTitle id='alert-dialog-slide-title'>
				{props.title}
				<img
					className='close-modal'
					alt=""
                    src = { close }
					onClick={() => {
						props.setStateModal(false);
					}} />
			</DialogTitle>
			<DialogContent  >
				<DialogContentText id='alert-dialog-slide-description'>
					{props.children}
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
};

export default Modal;
