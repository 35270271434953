import React, { useEffect, useContext, useState } from "react"; //useIntl
import Box from "@mui/material/Box";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { MdModeEdit } from "react-icons/md";
//Utils
import { MotorContext } from "../../../../context/MotorContext";
//Icons
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/AddBox";
import TuneIcon from "@material-ui/icons/Tune";
//Style
import styles from "./index.module.css";
//Custom Components

import ModalViewCampo from "../../../../componets/ModalViewCampo/index";
import ModalInputReference from "../../components/ModalEntradaReferencia/index";
import ModalConfirm from "../../../../componets/Custom/Utils/ModalDelete";
import TableData from "../../../../componets/TableData/index";
import SuccessMessage from "componets/SuccessMessage";
import EntradaFrom from "../../components/ModalEntradaFrom/index.js";
//Services
import inputElementService from "../../services/InputElementService.service";

const InputElements = (props) => {
  //Notification
  const [noti, setNoti] = useState(false);
  const [messageNoti, setMessageNoti] = useState("");
  const [severity, setSeverity] = useState("success");
  const [openModalDelete, setOpenModalDelete] = React.useState(false);

  const {
    obtenerElementosEntrada,
    state,
    selectRows,
    setSelectRows,
    editEntrada,
    setEditEntrada,
    rowView,
    modalViewCampo,
    showModalForm,
    setShowModalForm,
    rowReferencia,
    setRowReferencia,
    showModalReferencia,
    setShowModalReferencia,
    handleModalReferencia,
    handleAddItem,
    handleFuncion,
    statefuncion,
    handleModalForm,
    handleModalViewCampo,
    modalConfirmCancelProps, setModalConfirmCancelProps
  } = useContext(MotorContext);

  const handleConfirmClose = () => {
    setModalConfirmCancelProps({
      ...modalConfirmCancelProps,
        open: true,
        onYes: handleModalForm,
    });
  }

  useEffect(() => {
    !state.listEntrada.length && obtenerElementosEntrada();
  }, [obtenerElementosEntrada, state.listEntrada]);

  //Handlers
  const handleOpenModalDelete = () => {
    setOpenModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleCampoAlias = (cell) => {
    if (cell.row.alias) {
      return <div style={{ wordBreak: "break-all" }}>{cell.row.alias}</div>;
    } else {
      return <div style={{ wordBreak: "break-all" }}>{cell.row.nombre}</div>;
    }
  };

  const handleDeleteItem = () => {
    if (selectRows.length) {
      handleOpenModalDelete();
      /**/
    } else {
      showNoti("seleccione un elemento de la tabla", "error");
    }
  };

  const handleConfirmacionBorrado = () => {
    state.listEntrada.forEach((element) => {
      selectRows.forEach((e) => {
        if (e === element.id) {
          inputElementService
            .deleteCampoEntrada(element.id)
            .then((response) => {
              response.error.forEach((error) => showNoti(error, "error"));
              response.success.forEach((mensaje) => showNoti(mensaje));
              obtenerElementosEntrada();
            })
            .catch((error) => console.log(`Ocurrio un error: ${error}`));
        }
      });
    });
  };

  //Functions
  const showNoti = (mensaje, severityNoti) => {
    setMessageNoti(mensaje);
    setSeverity(severityNoti);
    setNoti(true);
  };

  const actions = (cell) => {
    return (
      <div className={styles.icons}>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip-3">Editar campo</Tooltip>}
        >
          <Button className="add-button">
            <MdModeEdit
              onClick={(e) => {
                e.stopPropagation();                
                handleModalForm();
                setEditEntrada(cell.row);
              }}
              style={{ fontSize: "1.5vw", cursor: "pointer", color: "#484848" }}
            />
          </Button>
        </OverlayTrigger>
        {cell.row.aplicanativo != "No" ? (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip-3">
                Generar Referencia Filtrado
              </Tooltip>
            }
          >
            <Button className="add-button">
              <TuneIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setRowReferencia(cell.row);
                  handleModalReferencia();
                  console.log(cell.row)
                }}
                style={{
                  fontSize: "1.5vw",
                  cursor: "pointer",
                  color: "#484848",
                }}
              />
            </Button>
          </OverlayTrigger>
        ) : (
          ""
        )}
      </div>
    );
  };

  //Columns Table
  const columnsnew = [
    { field: "id", headerName: "Id", width: 60 },
    {
      field: "aplicanativo",
      headerName: "Nativo",
      width: 130,
      editable: false,
    },
    {
      field: "alias",
      headerName: "Alias ",
      width: 250,
      editable: false,
      renderCell: handleCampoAlias,
    },
    {
      field: "descripcion",
      headerName: "Descripción",
      width: 400,
      editable: false,
    },
    {
      field: "",
      headerName: "",
      width: 200,
      editable: false,
      renderCell: actions,
      filterable: false
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: "15px",
      }}
    >
      <SuccessMessage
        open={noti}
        setNoti={setNoti}
        message={messageNoti}
        setMessageNoti={setMessageNoti}
        severity={severity}
      />
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            p: 1,
            textAlign: "start",
            paddingLeft: "10px",
            paddingTop: "23px",
          }}
        >
          <Box
            sx={{
              p: 1,
              width: "100%",
              textAlign: "start",
            }}
          >
            <span className="title-view">Elementos de entrada</span>
          </Box>

          <Box sx={{ p: 1, flexShrink: 10, textAlign: "left" }}>
            <Button
              className="add-button"
              onClick={() => {
                setShowModalForm(true);
              }}
            >
              <AddBoxIcon />
            </Button>
            {!selectRows.length ? (
              <Button className="add-button" disabled>
                <DeleteIcon color="disabled" />
              </Button>
            ) : (
              <Button
                className="add-button"
                onClick={() => {
                  handleDeleteItem();
                }}
              >
                <DeleteIcon />
              </Button>
            )}
          </Box>
        </Box>
        <div>
          <TableData
            columns={columnsnew}
            rows={state.listEntrada}
            setSelectRows={setSelectRows}
            onRowClick={() => {/* do nothing */}}
          />
        </div>
      </div>

      <EntradaFrom
        handleModalForm={handleConfirmClose}
        showModalForm={showModalForm}
        statefuncion={statefuncion}
        handleAddItem={handleAddItem}
        editEntrada={editEntrada}
        handleFuncion={handleFuncion}
        showNoti={showNoti}
      />

      <ModalViewCampo
        handleModalViewCampo={handleModalViewCampo}
        modalViewCampo={modalViewCampo}
        rowView={rowView}
      />

      <ModalInputReference
        showModalReferencia={showModalReferencia}
        handleModalReferencia={handleModalReferencia}
        rowReferencia={rowReferencia}
        setShowModalReferencia={setShowModalReferencia}
      />

      <ModalConfirm
        open={openModalDelete}
        onClose={handleCloseModalDelete}
        onYes={handleConfirmacionBorrado}
      />
    </div>
  );
};

export default InputElements;
