import React, { Component } from "react";
import App from "base-shell/lib";
import config from "./config";
import { MotorContextProvider  } from './context/MotorContext';
import Loader from "componets/Custom/Utils/Loader";
export default class Demo extends Component {
	constructor() {
		super();
		this.state = {
			loggedIn:
				(localStorage.getItem("loggedIn") &&
					JSON.parse(localStorage.getItem("loggedIn"))) ||
				false,
		};
	}
	render() {
		return <MotorContextProvider>
			<Loader/> 
			<App config={config}>
			</App> 
		</MotorContextProvider> ;
	}
}
