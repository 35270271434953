import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  label: {
    minWidth: "30%",
    // backgroundColor: "red",
    // color: (props) => props.color,
    // marginBottom: "2rem !important",
    // margin: " 0px 0px 4rem 0px !important",
    color: "#484848",
    font: "normal normal bold 12px Quicksand-Bold",
  },
});

const CssTextField = styled(TextareaAutosize)({
  minWidth: "60%",
  margin: "0px",
  font: "normal normal 12px Quicksand",
  color: "#484848",
  borderRadius: "5px",
  padding: "10px",
  "& .MuiOutlinedInput-root": {
    padding: "0px",
    "& fieldset": {
      color: "#484848",
      borderColor: "#707070",
    },
  },
});

export default function TextFieldMultilineModalForm(props) {
  const classes = useStyles(props);
  return (
    <div className="inputText" style={{}}>
      <label htmlFor={props.name} className={classes.label}>
        {props.labelText}
      </label>
      <CssTextField
        id="input-with-icon-textfield"
        variant="outlined"
        {...props}
        InputProps={{
          startAdornment: <InputAdornment position="start"></InputAdornment>,
        }}
      />
    </div>
  );
}
