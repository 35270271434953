import API from './API';
const ServiceEjecucion = {

    //Tablero Periodo de ejecucion
    getData: () => new Promise(
        (resolve, reject ) => {
            API.get('/api/export/GetTPE')
            .then( res => res.data)
            .then(data => {
                resolve(data)
                console.log(data)
            })
            .catch( error => reject(error))
        }
    ),

    //Detalle Tablero Periodo Ejecucion
    getDetailData: () => new Promise(
        (resolve, reject) => {
            API.get('/api/export/GetTPED')
            .then(res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch(error => reject(error))
        }
    ),

    getContracts: (params) => new Promise(
        (resolve, reject ) => {
            API.get('/api/export/GetContratoConvenio',params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),

}

export default ServiceEjecucion;