export const operMat = [
    {
        nombre: "+",
        value: "+",
    },
    {
        nombre: "-",
        value: "-",
    },
    {
        nombre: "/",
        value: "/",
    },
    {
        nombre: "*",
        value: "*",
    },
];

export const estado = [
    {
        nombre: "Activo",
        value: "1",
    },
    {
        nombre: "Inactivo",
        value: "2",
    },
];
export const logicOperatorList = [
    {
        nombre: "N/A",
        value: null,
    },
    {
        nombre: "AND",
        value: "AND",
    },
    {
        nombre: "OR",
        value: "OR",
    },
];

export const relationalOperatorList = [
    {
        nombre: "=",
        value: "=",
    },
    {
        nombre: "<>",
        value: "<>",
    },
    {
        nombre: "Is",
        value: "Is",
    },
    {
        nombre: "IsNot",
        value: "IsNot",
    },
];

export const optFuncion = [
    {nombre:"Truncar", value:"Truncar"}] 