/* eslint-disable react/jsx-key */
import React, { lazy } from "react";
import AuthorizedRoute from "base-shell/lib/components/AuthorizedRoute/AuthorizedRoute";
import UnauthorizedRoute from "base-shell/lib/components/UnauthorizedRoute/UnauthorizedRoute";
import AdministracionConfig from "../pages/Administracion/index";
import ElementosEntrada from "../pages/Administracion/views/inputElements/index";

const Home = lazy(() => import("../pages/Home/HomePage"));
const Login = lazy(() => import("../pages/Login/index"));
const Contratos = lazy(() => import("../pages/Contratos/pages/index"));
const SolicitudesDePago = lazy(() =>
  import("../pages/PayRequest/pages/Index/index")
);
const Reports = lazy(() => import("../pages/Reports/index"));

const validaRuta = (path, rolMenu) => {
  switch (path) {
    case "/contratos":
      if (rolMenu.contrato) return true;
      break;
    case "/administracion/motor_calculo":
      if (rolMenu.motorcalculo) return true;
      break;
    case "/administracion":
      if (rolMenu.true) return  true;
      break;
    case "/solicitudes_de_pago":
      if (rolMenu.solicitudesdepago) return true;
      break;
    case "/reportes":
      return rolMenu.reportes;
    default:
      return false;
  }
  return false;
}

function PrivateRoute({ component, path }) {
  let flag = false;
  if (window.localStorage.userInfo) {
    const userInfo = JSON.parse(window.localStorage.userInfo);
    const rolMenu = userInfo.rolmenu;
    console.log('userInfo', userInfo);
    flag = validaRuta(path, rolMenu);
  }
  
  if (JSON.parse(window.localStorage.getItem("loggedIn")) === true && flag) {
    return <AuthorizedRoute exact path={path} component={component} />;
  } else {
    return <UnauthorizedRoute path="/login" exact component={Login} />;
  }
}

const routes = [
  <UnauthorizedRoute path="/login" exact component={Login} />,
  <AuthorizedRoute path="/" exact component={Home} />,
  <AuthorizedRoute path="/home" exact component={Home} />,
  <PrivateRoute path="/contratos" exact component={Contratos} />,
  <PrivateRoute
    path="/administracion/motor_calculo"
    exact
    component={AdministracionConfig}
  />,
  <PrivateRoute
    path="/solicitudes_de_pago"
    exact
    component={SolicitudesDePago}
  />,
  <PrivateRoute
    path="/reportes"
    exact
    component={Reports}
  />,
  <AuthorizedRoute
    path="/administracion/motor-calculo"
    exact
    component={ElementosEntrada}
  />,
];

export default routes;
