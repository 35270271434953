import React, { lazy } from "react";
import locales from "./locales";
import routes from "./routes";
import parseLanguages from "base-shell/lib/utils/locale";
import Paginator from "componets/Paginator/Paginator";
import Box from '@mui/material/Box';
import './Styles.css';
import CssBaseline from '@mui/material/CssBaseline';

const LogOut = lazy(() => import("../pages/LogOut/index"));
const Loading = () => <div>Loading...</div>;
const Drawer = lazy(() => import("../componets/Drawer/index"));

const config = {
	locale: {
		defaultLocale: parseLanguages(["en", "de", "ru"], "en"),
		locales,
		persistKey: "base-shell:locale",
		onError: (e) => {
			//Uncomment this to show react-intl missing translation warnings
			return;
		},
	},
	auth: {
		persistKey: "base-shell:auth",
		signInURL: "/login",
	},
	routes,
	pages: {
		PageNotFound: lazy(() => import("../pages/PageNotFound/PageNotFound")),
	},
	components: {
		Loading,
	},
	containers: {
		AppContainer: ({ children }) => (
			<div >{children}</div>
		),

		LayoutContainer: ({ children }) => (
			<div className = 'Container' >				
				<CssBaseline />
				{
					JSON.parse(window.localStorage.getItem("loggedIn")) ? (< >
						<Drawer />
					</>) : null
				}
				<div className = 'Section' >{
					JSON.parse(window.localStorage.getItem('loggedIn')) &&
					<div className = 'header-container' >
						<Paginator />
					{JSON.parse(window.localStorage.getItem("loggedIn")) ? (< >
						<LogOut />
					</>) : null }
					</div>}
					<Box component="main" sx={{ flexGrow: 1 }}>						
						{children}
					</Box>
				</div>
			</div>
		),
	},
};

export default config;
