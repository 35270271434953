
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './index.module.css';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    backgroundColor: theme.palette.background.paper,
    '& .MuiFormControl-root ': {
      marginBottom: "3%"
    }
  },

}));
const userInfo = JSON.parse(window.localStorage.getItem("userInfo"))

const ModalViewCampo = (props) => {
  const classes = useStyles();


  return (
    <>
      <Modal size="lg" show={props.modalViewCampo} onHide={props.handleModalViewCampo} >
        <Modal.Header closeButton>
          <Modal.Title>{props.rowView.nombre ? props.rowView.nombre : props.rowView.alias}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className={classes.root}>
            <div className={styles.radioGroup}>
              <TextField name="tablaFilter" id="outlined-basic" value={props.rowView.alias} disabled label="Alias" variant="outlined" />
              <TextField name="campoFilter" id="outlined-basic" value={props.rowView.aplicanativo} disabled label="Nativo" variant="outlined" />
              <TextField name="campoFilter" id="outlined-basic" value={props.rowView.nombre} disabled label="Nombre" variant="outlined" />
              <TextField name="tablaFilter" id="outlined-basic" value={props.rowView.descripcion} disabled label="Descripción" variant="outlined" />
              <TextField name="tablaFilter" id="outlined-basic" value={props.rowView.tipovalordato} disabled label="Tipo de dato" variant="outlined" />
              <TextField name="campoFilter" id="outlined-basic" value={props.rowView.valor} disabled label="Tipo valor" variant="outlined" />
              <TextField name="campoFilter" id="outlined-basic" value={props.rowView.tipovalorfijo} disabled label="Valor fijo" variant="outlined" />
              <TextField name="tablaFilter" id="outlined-basic" value={props.rowView.funcion} disabled label="Nombre Función" variant="outlined" />

              <TextField name="campoFilter" id="outlined-basic" disabled label="Tabla" variant="outlined" />
              <TextField name="tablaFilter" id="outlined-basic" disabled label="Campo" variant="outlined" />
              <TextField name="campoFilter" id="outlined-basic" value={props.rowView.fechacreacion} disabled label="Fecha de creación" variant="outlined" />
              <TextField name="tablaFilter" id="outlined-basic" value={props.rowView.usuariocreacion} disabled label="Usuario Creación" variant="outlined" />
              <TextField name="tablaFilter" id="outlined-basic" value={props.rowView.fechaultimamodificacion} disabled label="Fecha Modificación" variant="outlined" />
              <TextField name="tablaFilter" id="outlined-basic" value={userInfo.correo} disabled label="Usuario Modificación" variant="outlined" />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer >
        </Modal.Footer>
      </Modal>
    </>
  )


}


export default ModalViewCampo