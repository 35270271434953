import API from './API';

const ServiceReferencia = {

    getData: () => new Promise(
        (resolve, reject ) => {
         
            API.get('/api/export/GetCER')
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),

    addData: (params) => new Promise(
        (resolve, reject ) => {
         
            API.post('/api/export/insertCER',params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),
    updateData: (params) => new Promise(
        (resolve, reject ) => {
         
            API.post('/api/export/UpdateCER',params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    ),
    deleteData: (params) => new Promise(
        (resolve, reject ) => {
         
            API.post('/api/export/DeleteCER',params)
            .then( res => res.data)
            .then(data => {
                resolve(data)
            })
            .catch( error => reject(error))
        }
    )

}

export default ServiceReferencia ;